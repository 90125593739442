@import "./style/vars";
@font-face {
    font-family: "RobotoCondensed-Regular";
    src:
        local("RobotoCondensed-Regular"),
        url(./fonts/RobotoCondensed-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins-regular";
    src:
        local("Poppins-Regular"),
        url(./fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "opensans-regular";
    src:
        local("OpenSans_Condensed-Regular"),
        url(./fonts/OpenSans_Condensed-Regular.ttf) format("truetype");
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    letter-spacing: 1px;
}

html {
    // font-family: "RobotoCondensed-Regular";
    font-family: "Poppins-regular";
    color: $main;
    font-size: 12px;
}

.findatasheets-logo-font {
    font-family: "Noto Sans NKo Unjoined", sans-serif;
}

.disable-scroll {
    overflow-y: hidden;
}

body {
    background-color: $cardBgc;
}

ul {
    list-style-type: none;
}

.findatasheets-container {
    height: calc(70vh + 40px);
    &-item {
        height: calc(50% - 10px);
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &-top {
            height: calc(60% - 20px);
            // margin-bottom: 20px;
        }
        &-bottom {
            height: 40%;
        }
    }
}

.container-height {
    height: calc(100vh - 215px);
    margin-bottom: 20px;
}

textarea,
input {
    outline: none !important;
    text-decoration: none !important;
}

textarea {
    border: none;
    overflow: auto;
    outline: none;
    background-color: inherit;
    padding: 10px;
    width: 100%;
    box-shadow: none;
    resize: none;
}

a,
a:focus,
a:active {
    text-decoration: none;
    color: $main !important;
    margin-bottom: 0 !important;
}

a {
    &:hover {
        color: $secondary !important;
    }
}

.app-container {
    // min-height: 120vh;
}

.scrollable-y {
    overflow-y: auto;
}

.clickable-label {
    // font-weight: 600;
    text-align: center;
    &:hover {
        cursor: pointer;
        color: $secondary;
    }
}

.btn {
    cursor: pointer;
    min-width: 32px;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    border-radius: 12px;
    &-border {
        border: 1px solid $main;
        border-radius: 16px;
        padding: 4px 6px;
        background-color: $hover;
        position: relative;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        // &:after {
        //     content: "";
        //     background: $hover;
        //     display: block;
        //     position: absolute;
        //     padding-top: 300%;
        //     padding-left: 350%;
        //     margin-left: -20px !important;
        //     margin-top: -120%;
        //     opacity: 0;
        //     transition: all 0.8s;
        // }
        // &:active:after {
        //     padding: 0;
        //     margin: 0;
        //     opacity: 1;
        //     transition: 0s;
        // }
        &:after {
            content: "";
            background: $hover;
            display: block;
            position: absolute;
            padding-top: 120%;
            padding-left: 120%;
            margin-left: 0px !important;
            margin-top: -0%;
            opacity: 0;
            transition: all 0.8s;
        }
        &:active:after {
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: 0s;
        }
    }
    &:not(:last-child) {
        margin-right: 50px;
    }
    &:hover {
        background-color: $hover;
    }
    &.selected {
        border-bottom: 2px solid $secondary;
        font-weight: 900;
        border-radius: 0;
        cursor: default;
        &:hover {
            // background-color: $hover;
        }
    }
    &-disabled {
        opacity: 0.5;
        cursor: default;
    }
    &-no-opacity {
        opacity: 0;
    }
}

.btn-selected {
    font-weight: 900;
}

.btn-negative-selected {
    &:hover {
        color: $negative;
    }
}

.onyx-table-item {
    &:not(:last-child) {
        border-bottom: 1px solid $secondary;
        padding: 5px 0;
    }
    &:last-child {
        padding-top: 5px;
    }
}

.odd-row {
    // border-radius: 8px;
    // background-color: $homepageBlue;
}

.mr20 {
    margin-right: 20px;
}

.ml20 {
    margin-left: 20px;
}

.m0auto {
    margin: 0 auto;
}

.mr10 {
    margin-right: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt80 {
    margin-top: 80px;
}

.mt {
    margin-top: 80px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.blur-bgc {
    background-color: $hover !important;
    filter: blur(4px) !important;
}

@media (max-width: 600px) {
    .mb20 {
        margin-bottom: 10px;
    }
}

.blink-border {
    animation: blink 1.4s infinite;
    padding: 2px 6px;
    border-radius: 10px;
}

@keyframes blink {
    50% {
        background-color: $homepageBlue;
    }
}

.pb10 {
    padding-bottom: 10px;
}

.pb20 {
    padding-bottom: 20px;
}

.font-weight600 {
    font-weight: 600;
}

.text-align-center {
    text-align: center;
}

canvas {
    margin: 0;
}

.card-container {
    background-color: $bgc;
    padding: 20px;
    border-radius: 8px;
}

.markovchains-title-item {
    min-width: 140px !important;
}

.portfolio-chart-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.flex {
    display: flex;
    &.justify-center {
        justify-content: center;
    }
    &.align-center {
        align-items: center;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.space-around {
        justify-content: space-around;
    }
    &.space-evenly {
        justify-content: space-evenly;
    }
    &.center-center {
        justify-content: center;
        align-items: center;
    }
    &.column {
        flex-direction: column;
    }
    &.wrap {
        flex-wrap: wrap;
    }
    &.row-reverse {
        flex-direction: row-reverse;
    }
    &.flex-end {
        justify-content: flex-end;
    }
    &.align-end {
        align-items: flex-end;
    }
    &.align-start {
        align-items: flex-start;
    }
    &-grow1 {
        flex-grow: 1;
    }
    &.column-reverse {
        flex-direction: column-reverse;
    }
}

.pos-rel {
    position: relative;
}

.display-none {
    display: none;
}

// table
.findatasheets-table-container {
    width: 100%;
    overflow-y: auto;
}

.findatasheets-table-title {
    font-weight: 600;
    text-align: start;
    font-size: 1rem;
    border-bottom: none;
    display: flex;
    align-items: flex-end;
    width: 90px;
}

.findatasheets-table-cell {
    text-align: start;
    border-bottom: none;
    display: flex;
    align-items: center;
    width: 100px;
    min-height: 50px;
    padding: 4px 8px;
    font-size: 12px;
}

.findatasheets-table-header {
    border-bottom: 1px solid $main;
}

.findatasheets-table-row {
    &:not(:last-child) {
        border-bottom: 1px solid $cardBgc;
    }
    &:hover {
        // color: $secondary;
        background-color: $hover;
    }
}

.selected-item-chart-title {
    height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.positive {
    color: $secondary;
}

.negative {
    color: $negative;
}

.single-item- {
    &title {
        font-size: 2.5rem;
    }
    &symbol {
        margin-right: 10px;
    }
    &arrow {
        margin-left: 10px;
    }
}

.challenge-completed-modal {
    position: fixed;
    bottom: 20px;
    // right: 20px;
    height: 170px;
    width: 500px;
    z-index: 1000;
    box-shadow: 2px 2px 20px rgba(64, 113, 171, 0.5);
    // animation
    right: -500px;
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    from {
        right: -500px;
    }
    to {
        right: 20px;
    }
}

.animated-out {
    animation: slideOut 1s forwards 3s;
}

@keyframes slideOut {
    from {
        right: 20px; /* Start from the visible position on the right */
    }
    to {
        right: -500px; /* Move back outside the viewport on the right */
    }
}

.challenge-completed-percent-bar {
    width: 75%;
    margin: 0 auto;
    border: 1px solid $secondary;
    border-radius: 20px;
    height: 15px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    width: 0;
    background: #4071ab;
    animation: progress-bar-animation 1s linear forwards;
}

@keyframes progress-bar-animation {
    to {
        width: 100%;
    }
}

.achievement-h1 {
    font-size: 1.5rem;
}

.achievement-h2 {
    font-size: 2rem;
}

.information-container {
    margin-top: 50px !important;
}

.information-item {
    margin-bottom: 20px;
    &-container {
        padding: 50px 15% 50px;
        font-size: 1.1rem;
        line-height: 1.7;
    }
}

.information-item-p {
    margin-bottom: 10px;
}

.information-item-main-title {
    margin-bottom: 25px;
    font-size: 2.5rem;
}

.information-item-title {
    margin-bottom: 20px;
}

::-webkit-scrollbar {
    width: 0px;
    height: 2px;
    position: absolute;
    right: 0;
}

::-webkit-scrollbar-thumb {
    background-color: $main;
    border-radius: 12px;
    z-index: 100;
}

.single-item-price-info {
    padding-bottom: 5px;
}

.single-company-price-info {
    padding-bottom: 5px;
}

.flex-grow {
    flex-grow: 1;
}

.width100 {
    width: 100%;
}

.border-bottom {
    border-bottom: 1px solid $main;
}

.noheader-media {
    margin-top: 55px;
}

.mobile-chart-size {
    // min-height: 250px !important;
    // max-height: 80% !important;
    &-pie {
        max-height: 200px;
    }
}

.err-msg {
    color: $negative;
}

h4 {
    font-size: 14px;
}

.page-margin-top {
    margin-top: 60px;
}

.margin-top-gap {
    margin-top: 80px;
}

@media (max-width: 1300px) {
    .container-height {
        height: 90vh;
    }

    .portfolio-medium-screen-top {
        height: 70% !important;
    }

    .portfolio-container-bottom {
        height: 30% !important;
    }
    .portfolio-pieline {
        margin-bottom: 10px !important;
    }
    .portfolio-piechart-item {
        width: 40% !important;
        margin-right: 10px !important;
    }
    .color-label-card-row {
        margin-bottom: 10px !important;
    }
    .mobile-chart-size-pie {
        margin: 0 auto;
        width: 100% !important;
    }
    .chart-wrapper:nth-child(2n) {
        margin-left: 0;
    }
    .portfolio-comparison-barcharts-item {
        // width: calc(50% - 10px);
        width: calc(50% - 5px) !important;
        &:first-child {
            margin-right: 10px !important;
        }
    }
}

.piechart-midtitle {
    font-size: 1rem;
}

@media (max-width: 920px) {
    html {
        // font-size: 14px;
    }
    .findatasheets-container {
        height: fit-content;
        &-item {
            height: fit-content;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &-top {
                height: fit-content;
            }
            &-bottom {
                height: fit-content;
            }
        }
    }
    .single-item-price-info {
        margin-left: 0;
        padding-bottom: 0;
    }
    .single-company-price-info {
        padding-bottom: 0;
    }
    .single-company-price-info {
        margin-left: 0 !important;
    }
    .single-sector-competitive-table-container {
        flex-direction: column;
        & :first-child {
            margin-bottom: 10px;
        }
    }
    .label-color-container {
        display: flex;
        flex-wrap: wrap;
        & > * {
            margin-right: 20px;
            margin-bottom: 10px;
        }
    }
    .label-color-item-span {
        padding: 0 !important;
        border-left: 0;
    }
    .label-color-item {
        padding-left: 0 !important;
    }
    .noheader-media {
        margin-top: 80px;
    }
    .media-container-height {
        height: fit-content;
    }
    .markovchains-title-item {
        min-width: 100px;
    }
    .container-height {
        // height: 70vh;
        height: fit-content;
        // margin-bottom: 20px;
    }
}

@media (max-width: 820px) {
    html {
        font-size: 12px;
    }
    .app-container {
        margin: 20px 0 0;
    }
    .single-sector-competitive-table {
        margin-left: 0 !important;
    }
    .mt80 {
        margin-top: 60px;
    }

    .mt {
        margin-top: 60px !important;
    }

    .markovchains-title-item {
        min-width: auto !important;
        width: 100px !important;
    }
    .noheader-media {
        margin-top: 40px !important;
    }
    .findatasheets-table-cell {
        font-size: 12px;
    }
    .page-margin-top {
        margin-top: 40px;
    }
    .margin-top-gap {
        margin-top: 60px;
    }
}

@media (max-width: 680px) {
    .noheader-media {
        margin-top: 60px !important;
    }
}

@media (max-width: 600px) {
    .findatasheets-container {
        margin-bottom: 10px !important;
        &-item {
            margin-bottom: 10px;
        }
    }
    .container-height {
        margin-bottom: 10px;
    }
    .card-container {
        padding: 10px;
    }
    .noheader-media {
        margin-top: 40px !important;
    }
    .mt80 {
        margin-top: 40px;
    }

    .mt {
        margin-top: 40px !important;
    }
}

@media (max-width: 550px) {
    .challenge-completed-modal {
        width: 250px;
    }
    .markovchains-title-item {
        width: 80px !important;
    }
}

@media (max-width: 400px) {
    .markovchains-title-item {
        width: 50px !important;
    }
}

#home-navigation {
    filter: none; /* Remove blur effect */
    position: relative; /* Ensure elements are placed correctly in the stacking order */
    z-index: 1;
}