.financial-statements-table- {
    &btns {
        width: fit-content;
        margin: 0 auto;
    }
    &no-data-msg {
        font-size: 1.2rem;
        padding: 20px;
    }
    &homepage-btn {
        width: fit-content;
        margin-left: 20px;
    }
}

.financial-statements-table-container {
    height: 90vh;
    overflow-y: auto;
    margin: 20px 0;
    width: 100%;
}

.pagination-bottom-btns {
    width: 100%;
    margin-top: 20px;
}

.pagination-top-btns {
    margin-bottom: 10px;
    margin: 0 auto;
}

.pagination-btns-daterange {
    margin: 0 5px;
}

@media (max-width: 730px) {
    .financial-statements-table-container {
        margin: 0 0 10px;
    }
}
