.currencies-linechart-table-container {
    width: 100%;
}

.macro-linechart-container {
    margin-left: 20px;
    width: 100%;
}

.macro-color-label-container {
    width: 150px;
}

.currencies-table-title {
    font-weight: 600;
}

.findatasheets-table-table {
    margin-top: 20px;
}

@media (max-width: 900px) {
    .macro-color-label-container {
    width: 100%;
    margin-bottom: 20px;
    height: 200px;
    }
    .macro-linechart-container {
        margin-left: 0;
        margin-bottom: 20px;
        width: 100%;
    }
    .macro-currencies-return-sharpe {
        margin-top: 240px;
    }
}
