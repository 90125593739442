
.empty-portfolio- {
    &container {
        margin-top: 80px;
        width: 80%;
        margin: 80px auto 20px;
    }
    &p {
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
    &span {
        font-weight: 600;
    }
}