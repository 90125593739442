@import "../../../../../style/vars.scss";

.create-fund-banner-item {
    width: 100px;
    object-fit: contain;
    &:not(:last-child) {
        margin-right: 10px;
    }
    &:hover {
        border: 2px solid $main;
        cursor: pointer;
    }
}

.create-fund-banner-btn {
    font-size: 2.2rem;
    cursor: pointer;
}