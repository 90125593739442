@import "../../../style/vars.scss";

.nav-items-container {
    background-color: $bgc;
    position: fixed;
    left: -7px;
    top: 55px;
    z-index: 5;
    box-shadow: none;
    width: 80px;
    transition: width 0.4s ease;
    height: calc(100% - 70px);
    & div.sidemenu-icon-item-label {
   
        opacity: 0;
        transform: translateX(10px); /* Adjust the value as needed for the desired spacing */
        transition: opacity 0.8s ease, transform 0.4s ease;
    }
    &:hover {
        width: 200px;
        & div.sidemenu-icon-item-label {
            opacity: 1;
            transform: translateX(0);
            display: block;
        }
    }
}

.main-nav-item {
    width: 100% !important;
    padding: 20px 30px;
    &:hover {
        background-color: $hover;
    }
    &:not(:last-child) {
        margin-bottom: 0 !important;
    }
}

.sidemenu-nav {
    color: $main;
    width: 100%;
}

.sidemenu-icon-item {
    margin-right: 10px;
    font-size: 2rem;
}

.active-sidemenu-nav {
    // color: $secondary !important;
    width: 100%;
    font-weight: 900;
    font-size: 1.3rem;
}

.active-nav {
    background-color: $secondary !important;
    color: $cardBgc !important;
    &:hover {
        color: $cardBgc !important;
    }
}

.nav-item {
    width: 110px;
    height: fit-content;
    padding: 6px;
    text-align: center;
    border-radius: 8px;
    font-weight: 600;
    background: $cardBgc;
    box-shadow: 0 0 8px rgba(50, 98, 115, 0.5);
    color: $main;
    // font-size: 1.2rem;
    &:hover {
        color: $hover;
    }
    &-menu {
        box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
        width: 100%;
        height: fit-content;
        padding: 6px;
        text-align: start;
        background: $cardBgc;
        color: $main;
        font-size: 1rem;
        margin-bottom: 10px;
        &:hover {
            color: $hover;
        }
        &-bottom {
            font-size: 0.9rem;
            background-color: cardBgc;
            &:hover {
                color: $hover;
            }
        }
    }
}

.header-pages-more-open {
    position: absolute;
    left: 150px !important;
    top: 450px !important;
    z-index: 200;
}

.header-page-extra-item {
    font-size: 0.9rem !important;
}

.navbar-page-btn {
    width: fit-content;
    font-size: 1.2rem;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (max-width: 920px) {
    .nav-items-container {
        display: none !important;
    }
}
