.follow-btn {
    height: fit-content;
    display: flex;
    width: 110px;
    margin-right: 0;
    text-align: start;
}

.edgar-btn {
    width: 110px;
    margin-top: 10px;
}

@media (max-width: 920px) {
    .follow-btn {
        // margin-bottom: 20px;
        margin-right: 0 !important;
        margin-top: 0;
    }
}

@media (max-width: 820px) {
    .edgar-btn {
        margin-bottom: 20px;
    }
}
