.indices-commodities-linechart {
    margin-bottom: 20px;
    width: 100%;
}

.indices-commodities-line-chart {
    width: 100%;
}

.linechart-container-height {
    & > * {
        height: 400px !important;
    }
}

@media (max-width: 1100px) {
    .indices-commodities-linechart {
        flex-direction: column;
    }
}
