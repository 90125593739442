.need-user-container {
    margin: 120px auto 0;
    width: fit-content;
}

.need-user-btns {
    width: 40%;
    height: 100%;
    margin: 0 auto;
}

.need-user-btn {
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
}

.need-user-title {
    margin-bottom: 20px;
}
