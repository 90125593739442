.homepage-barchart-item-mb10 {
    margin-bottom: 10px;
}

.homepage-chart {
    height: 65% !important;
}

.homepage-charts-container {
    margin-bottom: 20px;
}

.homepage-barcharts-period-filters {
    font-size: 0.9rem;
}

.homepage-barchart-title {
    margin-right: 10px;
    padding: 10px 0;
    padding-top: 0;
}

.homepage-dropdown-label {
}

.homepage-dropdown-select-filter-item {
    min-width: 120px;
    height: fit-content;
    // padding: 10px 40px;
    // background: $bgc;
    // color: $main;
    font-size: 1.2rem;
    border-radius: 14px;
}

.homepage-chart-item {
    width: 50%;
}

.homepage-barchart-period-filter {
    padding: 5px 3px;
    // padding-top: 0;
    min-width: 10px;
//    &:not(:last-child) {
    margin-right: 0px !important;
//    }
}

@media (max-width: 920px) {
    .homepage-charts-container {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}

