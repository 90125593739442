.selected-sentence- {
    &container {
        text-align: center;
        margin-top: 20px;
        font-size: 1.4rem;
        width: 80%;
    }
    &title {
        width: fit-content;
        font-weight: 600;
    }
    &content {
    }
}
