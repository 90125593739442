@import "../../../../style/vars.scss";

.join-matchweek-card {
    min-height: 200px;
}

.matchweek-participants-table-item {
    padding-bottom: 5px;
    &:not(:last-child) {
        margin-bottom: 5px;
        border-bottom: 1px solid $hover;
    }
    &:hover {
        cursor: pointer;
        color: $secondary;
    }
}

.matchweek- {
    &type {
        font-size: 2rem;
    }
    &participants {
        font-size: 2.5rem;
    }
    startdate {
        font-size: 1.5rem;
    }
    enddate {
        font-size: 1.5rem;
    }
}

.matchweek-participants-table-item {
    min-width: 100px;
}

.matchweek-participants-table-item .switch-icon {
    opacity: 0;
}

.matchweek-participants-table-item:hover .switch-icon {
    opacity: 1;
}
