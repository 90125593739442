@import "../../../style/vars.scss";

.single-company-header {
    margin-top: 20px;
    margin-bottom: 0 !important;
    position: relative;
}

.single-company-header-main {
    margin-right: 20px;
}

.single-company-sector-clickable {
    margin: 5px 0;
    &:hover {
        color: $hover;
        cursor: pointer;
    }
}

.single-company-price-info {
    margin-left: 10px;
    font-size: 1.4rem;
}

.single-company-header-margin {
    margin: 0 auto 10px;
}

@media (max-width: 920px) {
    .single-company-title {
        text-align: center;
    }
    .single-company-header-info-container {
        margin: 0 auto;
    }
    .single-company-header-main {
        margin-right: 0;
    }
}

.single-company-header-info-item {
    border-left: 2px solid $main;
    padding-left: 5px;
    margin-left: 5px;
}

.single-company-header-secondary-info {
    margin-right: 20px;
}

.single-company-title {
    font-size: 2.5rem;
}

.financial-statements-header- {
    &container {
        margin-bottom: 20px;
        padding: 20px 0;
        margin-top: 60px;
        width: 100%;
        &-top {
            width: 50%;
        }
    }
    &company {
        margin-right: 10px;
        font-size: 2rem;
    }
    &symbol {
        font-size: 1.5rem;
    }
    &info-item {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.single-company-price-arrow {
    margin-right: 5px;
    &-up {
        color: $secondary;
    }
    &-down {
        color: $negative;
    }
}

.financial-statements-header-follow-btn-container {
    align-self: flex-end;
}

@media (max-width: 850px) {
    .financial-statements-header-container {
        flex-direction: column;
        text-align: center;
        margin-top: 60px;
        align-items: center;
        width: 100%;
        &-top {
            width: 100%;
        }
        .financial-statements-header-item {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
        .financial-statements-header-info {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .financial-statements-header-follow-btn-container {
        align-self: auto;
    }
}

.financial-statements-header-follow-btn-container {
    position: relative;
}

.financial-statements-header-follow-msg {
    position: absolute;
    width: 300px;
    right: 0;
    top: 70px;
    border: 2px solid $main;
    background-color: $bgc;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
}

@media (max-width: 820px) {
    .single-company-header {
        margin-top: 20px !important;
    }
    .financial-statements-header-follow-msg {
        top: 190px;
    }
    .financial-statements-header-follow-msg-company {
        top: 500px;
    }
}

// single-company-header-item-name
@media (max-width: 920px) {
    .single-company-header-item-name {
        flex-direction: column;
        align-items: center;
    }
    .single-company-header-main {
        & :not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .single-company-currency-in {
        margin-bottom: 0 !important;
    }
}
