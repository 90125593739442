.macro-currencies-top-container {
    margin-bottom: 20px;
}

.macro-currencies-table-cell {
width: 150px;
}

.macro-container-height {
    height: 75vh;
}

@media (max-width: 920px) {
    .macro-container-height {
        height: fit-content;
    }
}