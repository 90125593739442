@import "../../style/vars.scss";

.app-tutorial {
    overflow-y: auto;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    // min-width: 60vw;
    // max-width: 80vw;
    width: 700px;
    // height: 80vh;
    box-shadow: 0 0 8px rgba(50, 98, 115, 0.5);
    background-color: $bgc;
    position: fixed;
    &-header {
        padding: 20px;
        background-color: $hover;
    }
    &-p {
        font-size: 1.2rem;
        &-strong {
            font-size: 1.2rem;
            font-weight: 600;
            margin-right: 5px;
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    &-nav-btn {
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    &-btn {
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &-level-btn {
        margin-right: 0 !important;
        width: 200px;
    }
    &-level-center-btn {
        margin: 0 20px !important;
        width: 200px;
    }
    &-row {
        padding: 10px;
    }
    &-question {
        &-title {
            font-size: 1.4rem;
            margin-bottom: 10px;
        }
        &-row {
            // margin-bottom: 5px;
        }
    }
    &-slide-title {
        margin-bottom: 20px;
    }
}

.slide-container {
    overflow-y: auto;
}

@media (max-width: 1300px) {
    .app-tutorial-level-btn {
        width: 180px;
    }
}

@media (max-width: 1000px) {
    .app-tutorial-level-btn {
        width: 150px;
    }
}

@media (max-width: 800px) {
    .app-tutorial {
        max-width: none;
        min-width: none;
        width: 95vw;
    }
    .app-tutorial-level-btn {
        min-width: 120px;
    }
}

@media (max-width: 500px) {
    .app-tutorial-level-btn {
        min-width: none !important;
        width: fit-content;
    }
}