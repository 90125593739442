@import "../../../../style/vars.scss";

.single-crypto-header- {
    &main-container {
        margin-top: 50px;
        position: relative;
        margin-bottom: 20px;
    }
    &container {
        margin-top: 50px;
    }
    &title {
        margin-bottom: 5px;
        font-size: 1.5rem;
    }
    &sub-title {
        margin-bottom: 5px;
    }
    &item {
        border-left: 3px solid $main;
        padding-left: 5px;
        margin-left: 5px;
    }
}

.follow-btn-crypto-header {
    margin: 0 !important;
}

.financial-statements-header-follow-msg {
    top: 70px;
}

@media (max-width: 820px) {
    .single-crypto-header- {
        &container {
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .single-crypto-header-mobile-width {
        width: 100%;
        & > * {
            margin: 0 auto;
        }
    }
    .crypto-header-follow-msg {
        top: 240px;
    }
}

@media (max-width: 920px) {
    .single-crypto-header-sub-title {
        display: flex;
        justify-content: center;
    }
    .single-crypto-header-title {
        flex-direction: column;
    }
    .financial-statements-header-follow-msg-mobile {
        left: 50%;
        transform: translateX(-50%);
        top: 170px;
    }
    .follow-btn-crypto-header {
        margin-top: 20px !important;
    }
    .crypto-header-follow-msg {
        left: 50%;
        transform: translateX(-50%);
    }
}
