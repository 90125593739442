.main-content-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}

// @media (max-width: 1300px) {
//     .main-content-container {
//         width: 90% !important;
//     }
// }

@media (max-width: 920px) {
    .main-content-container {
        width: 90% !important;
    }
}

@media (max-width: 820px) {
    .main-content-container {
        width: 95% !important;
        margin-top: 0px !important;
    }
}

@media (max-width: 600px) {
    .main-content-container {
        width: 100% !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
}
