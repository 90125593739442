@import "../../style/vars.scss";

.info-popup-{
    &container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $main;
        padding: 10px;
        background-color: $bgc;
        box-shadow: 0 0 20px rgba(50, 98, 115, 0.5);
        border-radius: 12px;
        font-size: 0.8rem;
        max-width: 50%;
        height: fit-content;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 100;
        border: 1px solid $main;
    }
    &title {
        text-align: start;
    }
    &content {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: start;
        font-weight: 500;
        font-size: 1.2rem;
    }
}
