@import "../../../style/vars.scss";

.header-search- {
    &container {
        width: 100%;
    }
    &bar {
        height: 40px;
        & > * {
            height: 100%;
        }
    }
}

.header-search-custom-dropdown-indicator {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    background-color: $hover;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
}

.custom-option {
    width: 100%;
    text-align: start;
    padding: 10px;
    background-color: $bgc;
    &:not(:last-child) {
        border-bottom: 2px solid #eeeeee;
    }
    &:hover {
        cursor: pointer;
        background-color: $hover;
    }
}

.custom-option-img {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin: 0 5px;
}

.header-search-mobile-nav {
    font-size: 2rem;
    padding: 5px;
    border-radius: 10px;
    &:hover {
        cursor: pointer;
        background-color: $hover;
    }
}