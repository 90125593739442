@import "../../../../style/vars.scss";

.financial-statements-table- {
    &row {
        padding: 10px 5px;
        border-radius: 6px;
        &:hover {
            color: $secondary;
        }
        &-odd {
            background-color: $homepageBlue;
        }
    }
    &cell- {
        &subtitle {
            width: 280px;
            font-size: 0.9rem;
            margin-right: 50px;
        }
        &item {
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            width: 100px;
        }
    }
    &align {
        margin-left: 100px;
    }
}

@media (max-width: 550px) {
    .financial-statements-table-cell-subtitle {
        width: 40%;
    }
    .financial-statements-table-row {
        justify-content: space-between;
    }
}
