@import "../../../style/vars.scss";

.quest-container {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.quest-stars {
    margin-right: 20px;
    font-size: 2rem;
}

.quest-star {
    font-size: 3rem;
    &-completed {
        color: $yellowMustard;
    }
}

@media (max-width: 1000px) {
    .quest-star {
        font-size: 2rem;
    }
}
