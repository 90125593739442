@import "../../../../style/vars.scss";

.create-fund-crest {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    height: 100px;
    background-color: transparent;
    border: 1px solid $main;
    border-radius: 10px;
    position: relative;
    // margin-right: 20px;
}

.create-fund-crest-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
}

.create-fund-crest:hover .create-fund-crest-btn {
    display: block;
}

.create-fund-crest-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    clip-path: polygon(100% 75%, 99% 0%, 0% 0%, 0% 75%, 50% 100%);
}