@import "../../../../style/vars.scss";

.homepage-feed-post {
    width: 100%;
    margin-bottom: 20px;
}

.homepage-feed-post-arrow {
    font-size: 2rem;
    color: $main;
    cursor: pointer;
    padding: 2px;
}

.homepage-feed-user- {
    &img {
        width: 40px;
        object-fit: contain;
        border-radius: 8px;
        margin-right: 10px;
    }
    &name {
        font-weight: 600;
        cursor: pointer;
        font-size: 1.2rem;
        // margin-right: 20px;
        &:hover {
            color: $secondary;
        }
    }
    &text {
        font-size: 1.2rem;
        padding: 0 5px 10px;
    }
    &time {
        font-size: 1rem;
    }
    &content {
        // padding: 10px;
        // width: 90%;
        // margin: 0 auto;
        &-img {
            width: 100%;
            height: 250px;
            object-fit: contain;
        }
        &-toggle {
            margin-bottom: 10px;
            &-btn {
                border: 1px solid $main;
                padding: 4px;
                border-radius: 12px;
                cursor: pointer;
                &:not(:last-child) {
                    margin-right: 5px;
                }
                &-selected {
                    background-color: $main;
                }
            }
        }
    }
    &btn-icon {
        margin-right: 4px;
        font-size: 2rem;
        cursor: pointer;
        &:hover {
            color: $secondary;
        }
    }
    &btn-container {
        padding: 0 20px;
        font-size: 1.2rem;
        &-item {
        }
    }
}

.post-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(208, 208, 208, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.post-modal-content {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 85%;
    width: 60%;
    overflow-y: auto;
}

.copy-portfolio-modal-content {
    height: fit-content;
}

.homepage-post-modal-close-btn {
    padding: 4px;
    cursor: pointer;
    font-size: 1.2rem;
}

.homepage-post-modal-container {
    max-height: 70vh;
    margin: 0 auto;
    width: 60vw;
    overflow-y: auto;
}

.homepage-post-modal- {
    &comments {
        // height: 200px;
    }
    &comment {
        padding-bottom: 10px;
        font-size: 1.1rem;
        &:first-child {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 2px solid $cardBgc;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
            border-bottom: 2px solid $cardBgc;
        }
    }
    &img {
        width: 30px;
        object-fit: contain;
        border-radius: 8px;
        margin-right: 10px;
    }
}

.feed-post-user-profile-img {
    width: 60px;
    object-fit: contain;
    border-radius: 50%;
}

.home-feed-post-user-img {
    // font-size: 2rem;
    padding-right: 5px;
    display: flex;
    align-items: center;
    font-size: 3rem;
    height: 100%;
}

.homepage-post-modal-user-comment {
    margin-left: 35px;
}

.post-modal-add-comment-container {
    background-color: $cardBgc;
}

.post-modal-add-comment-icon {
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        color: $secondary;
    }
    &-container {
        padding: 0px 10px 10px 0px;
    }
}

.homepage-post-modal-post-reaction- {
    &container {
        margin-left: 35px;
        margin-top: 4px;
    }
    &item {
        cursor: pointer;
        font-size: 1.2rem;
        &:hover {
            color: $secondary;
        }
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.homepage-feed-user-btn-container {
    border-top: 1px solid $main;
    padding-top: 10px;
}

.feed-bar-label {
    font-size: 1.2rem;
    min-width: 10px;
    &:hover {
        cursor: pointer;
        color: $secondary;
    }
}

.homepage-feed-post-counter-span {
    font-size: 1.3rem;
    padding-left: 5px;
}

.homepage-feed-chart-container {
    padding: 0 10px;
}

.feed-post-table {
    border-bottom: 1px solid $main;
    overflow-y: auto;
    height: 20vh;
    margin-bottom: 20px;
}

.feed-post-table-cell {
    min-height: fit-content;
    width: 25%;
    margin: 0 auto;
}

.post-modal-mobile- {
    &top {
        position: absolute;
        top: 10px;
        width: calc(100% - 20px);
        // width: 100%;
        height: 50px;
    }
    &main {
        height: calc(100% - 135px);
        margin-top: 0px;
        overflow-y: auto;
    }
    &bottom {
        position: absolute;
        bottom: 10px;
        width: calc(100% - 40px);
        height: 100px;
    }
    &textarea {
        height: calc(100% - 30px);
    }
}

.post-return-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    font-size: 2rem;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: $main;
    justify-content: center;
    margin-right: 5px;
}

.post-return-str {
    text-align: center;
    &-container {
        background-color: $hover;
        border-radius: 30px;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
    &-span {
        font-size: 1.4rem;
        font-weight: 600;
    }
    &-lower {
        font-weight: 600;
    }
}

@media (max-width: 1200px) {
    .homepage-post-modal-container {
        width: 70vw;
    }
}

@media (max-width: 920px) {
    .homepage-post-modal-container {
        width: 80vw;
    }
}

@media (max-width: 820px) {
    .homepage-post-modal-container {
        width: 90vw;
    }
    .home-feed-post {
        padding: 30px;
    }
    .post-modal-content {
        height: 95%;
        width: 90%;
    }
    .homepage-post-modal-container {
        max-height: 90%;
    }
}

@media (max-width: 620px) {

    .feed-post-table-cell {
       font-size: 0.8rem;
       width: 20%;
    }

    .homepage-feed-user-text {
        font-size: 0.9rem;
        margin-top: 5px;
    }
    .homepage-feed-chart-container {
        height: 150px !important;
    }

    .post-return-str {
        &-container {
            font-size: 0.9rem !important;
        }
        &-span {
            font-size: 1rem !important;
        }
    }

    .profile-post-return-icon {
        font-size: 2rem !important;
    }
}

@media (max-width: 600px) {
    .post-modal-mobile- {
        &bottom {
            width: calc(100% - 20px);
        }
        &main {
            margin-top: 50px;
        }
    }
    .post-return-str {
        text-align: center;
        &-container {
            width: fit-content;
            // margin-left: auto;
        }
        &-span {
            font-size: 1.1rem;
        }
    }
    .post-return-icon {
        font-size: 1.8rem;
        height: 25px;
        width: 25px;
    }
}
