@import "../../style/vars.scss";

.feedback-modal-header {
    background-color: $hover;
    padding: 10px;
}

.feedback-modal-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 2px solid $main;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 1.5rem;
    background-color: $bgc;
    cursor: pointer;
    z-index: 200;
}

.feedback-modal-icon {
    margin-right: 10px;
    font-size: 2rem;
}

.feedback-modal-notification {
    position: fixed;
    bottom: 60px;
    right: 15px;
    border: 1px solid $negative;
    background-color: $negative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 1.2rem;
    z-index: 500;
    color: $bgc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-modal {
    position: fixed;
    bottom: 20px;
    right: 15px;
    border: 2px solid $main;
    background-color: $bgc;
    z-index: 2000;
    width: 400px;
}

.feedback-modal-content {
    padding: 0 20px 20px;
}

.feedback-textarea {
    font-size: 1.2rem;
    border: 1px solid #484848;
    border-radius: 20px;
    padding: 10px;
    background-color: #f1f6fc;
    // width: 70%;
    margin: 0 auto;
    height: 150px;
    overflow-y: auto;
}

.feedback-modal-bottom-btn {
    max-width: 70px;
}

.feedback-modal-p {
    font-size: 1.1rem;
}

@media (max-width: 600px) {
    .feedback-modal {
        position: absolute;
        width: 95vw;
        height: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .feedback-modal-bottom-btn {
        width: 50px !important;
        margin: 20px 0 0;
        min-width: fit-content;
    }
    .feedback-modal-form {
        padding-bottom: 0;
    }
}