@import "../../style/vars.scss";

.macro-moving-header-slide {
    width: 260px !important;
    & > * {
        height: 100%;
    }
}

.moving-header- {
    item-img {
        // width: 100px;
    }
    &slider {
        margin: auto;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        // height: 140px;
        // height: 20vh;
        display: grid;
        place-items: center;
        overflow: hidden;
        // border-top: 1px solid $main;
        // border-bottom: 1px solid $main;
        &::before,
        &::after {
            content: "";
            height: 100%;
            position: absolute;
            width: 10%;
            z-index: 2;
        }
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ("180deg");
        }
    }
    &slide {
        display: flex;
        align-items: center;
        padding: 10px;
        // margin-top: 10px;
        padding-bottom: 0px;
        perspective: 200px;
        &-item {
            width: 100% !important;
            transition: transform 1s;
            padding: 20px;
            background-color: $bgc;
            border-radius: 8px;
            &:hover {
                transform: translateZ(20px);
                padding: 20px;
            }
            &-homepage {
                padding: 10px;
            }
        }
        &-homepage {
            width: 250px;
            padding: 0 10px;
            perspective: 100px;
            background-color: $cardBgc;
        }
        &-macro-inflation {
            width: 320px;
            padding: 0px 0 20px 20px;
            perspective: 200px;
        }
    }
    &slide-track-homepage {
        animation: scroll-homepage 70s linear infinite;
        &:hover {
            animation-play-state: paused;
        }
    }
    &slide-track-macro-inflation {
        animation: scroll-macro-inflation 200s linear infinite;
        &:hover {
            animation-play-state: paused;
        }
    }
}

.moving-header-item-info-container-macro-inflation {
    & :first-child {
        margin-bottom: 10px;
        min-width: 70px;
    }
}

.moving-header-item-title-macro-inflation {
    font-size: 1.3rem;
    font-weight: 600;
}

.moving-header-item-data-macro-inflation {
    font-size: 1.7rem;
}

@keyframes scroll-homepage {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 9));
    }
}

@keyframes scroll-macro-inflation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-320px * 7));
    }
}
