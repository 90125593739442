@import "../../../style/vars.scss";

.calendar-day- {
    &container {
        overflow: auto;
        padding-right: 10px;
        background-color: $bgc;
        height: 170px;
        height: 120px;
    }
    &datenumber {
        font-weight: 600;
        margin-bottom: 10px;
        text-align: start;
    }
    &symbol {
        text-align: start;
        padding: 5px;
        font-size: 0.9rem;
        color: $secondary;
        font-weight: 600;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 5px;
        &:hover {
            cursor: pointer;
        }
    }
    &macro {
        text-align: start;
        padding: 5px;
        font-size: 0.9rem;
        margin-bottom: 5px;
    }
    &company {
        text-align: start;
        margin-bottom: 20px;
        font-size: 0.9rem;
        margin-left: 10px;
    }
    &info {
        text-align: start;
        font-size: 0.9rem;
    }
    &singleday {
        & > * {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    &odd-item {
        background-color: $homepageBlue;
    }
}
