@import '../../../../style/vars.scss';

.fund-playoff-return {
    background-color: $hover; 
    // border-radius: 12px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fund-playoff-img {
    margin-right: 10px !important;
}

.fund-playoff-img-reversed {
    margin-left: 10px;
}

.fund-playoff-member-img {
    margin-right: 10px !important;
}

.fund-playoff-member-img-reversed {
    margin-right: 0 !important;
    margin-left: 10px;
}

.fund-playoff-item {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.fund-playoff-member-item {
    width: 300px;
}