@import "../../../style/vars.scss";

ul {
    list-style-type: disc;
    padding: 0;
    margin-left: 20px;
}

li::marker {
    font-size: 1.5rem;
    color: $main;
}

.guide-container {
    margin-top: 20px !important;
}

.guide-ul {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

ul.guide-ul > li {
  font-size: 1.4rem;
  }

.guide-page-title {
    margin-bottom: 20px;
}

.guide-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.guide-subtitle {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.guide-p {
    font-size: 1.4rem;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.guide-btn {
    width: fit-content;
    padding: 6px 10px;
}