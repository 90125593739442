@import "../../style/vars.scss";

.dropdown-select-filter-item {
    min-width: 120px;
    height: fit-content;
    padding: 10px 40px;
    background: $bgc;
    color: $main;
    // margin-right: 20px;
    font-size: 1.2rem;
    border-radius: 14px;
    // display: flex;
    // align-items: center;
    // &:not(:last-child) {
    //     margin-right: 20px;
    // }
    &:hover {
        color: $secondary;
    }
}

.dropdown-select-filter-container {
    // border-bottom: 1px solid $main;
    // width: 80%;
    background-color: $bgc;
    margin: 20px auto;
    // padding: 10px;
}

.dropdown-indicator {
    margin-left: 20px;
    &-homepage {
        margin-left: 10px;
    }
}

.dropdown-label {
    display: flex;
    justify-content: center;
}

.incomplete-dropdown {
    color: $negative;
}

// @media (max-width: 1300px) {
//     .dropdown-select-filter-container {
//     //   margin-top: 10px;
//     }
// }

@media (max-width: 1300px) {
    .dropdown-select-filter-container {
        margin: 10px auto;
    }
}

@media (max-width: 1200px) {
    .dropdown-select-filter-container {
        border-bottom: none;
    }
}

@media (max-width: 1000px) {
    .dropdown-select-filter-container {
        flex-wrap: wrap;
        & > * {
            // margin-bottom: 20px;
        }
    }
}

@media (max-width: 820px) {
    .dropdown-select-filter-container {
        margin-top: 10px !important;
    }
}

@media (max-width: 600px) {
    .dropdown-select-filter-container {
        margin: 10px 0 !important;
    }
}