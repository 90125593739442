@import "../../../style/vars.scss";

.fundamental- {
    &page-container {
        // margin-bottom: 30px;
        // width: 80%;
        flex-wrap: wrap;
        background-color: $bgc;
        padding: 10px 10px;
        margin-bottom: 20px;
    }
    &filter-container {
    }
}

@media (max-width: 730px) {
    .fundamental-page-container {
        flex-wrap: wrap;
        & > * {
            margin-top: 10px;
        }
    }
}

@media (max-width: 600px) {
    .fundamental-page-container {
        margin-bottom: 10px;
    }
}
