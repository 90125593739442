@import "../../../../style/vars.scss";

.fund-members- {
    &username {
        font-size: 1.2rem;
        // font-weight: 600;
        &:hover {
            color: $secondary;
            cursor: pointer;
        }
    }
    &img {
        border: 3px solid;
        padding: 10px;
        border-top: none;
        border-radius: 50%;
        font-size: 1.8rem;
        margin-right: 20px;
        display: flex;
        align-items: center;
    }
    &icon {
        font-size: 2rem;
    }
    &item-trophies-container {
        min-width: 100px;
    }
    &amount {
        font-size: 1.1rem;
        margin-left: 10px;
    }
}
