@import "../../style/vars.scss";

.card-container-title-container {
    margin-bottom: 15px;
}

.card-container-info-btn {
    padding: 2px !important;
    font-size: 0.9rem;
    min-width: 10px !important;
}

.custom-multi-value {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 4px;
}

.custom-multi-value span {
    margin-left: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    &:hover {
        color: $negative;
    }
}

//   .custom-multi-value span:hover {
//     color: #666;
//   }
