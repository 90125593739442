@import "../../../../style/vars.scss";

.fund-logs-tableitem {
    width: 20%;
    font-size: 1.2rem;
    text-align: center;
}

.fund-logs-tablerow {
    &:hover {
        color: $secondary;
    }
    &:not(:last-child) {
        margin-bottom: 20px;
        border-bottom: 1px solid $hover;
        padding-bottom: 20px;
    }
}

.fund-logs-tableheader {
    font-weight: 600;
}
