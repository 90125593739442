@import "../../style/vars.scss";

.tutorial-content-container {
    margin-top: 20px !important;
    position: relative;
}

.tutorial-sections-container {
    width: 200px;
    margin-top: 20px;
    box-shadow:
        -1px 0 1px -1px #484848,
        1px 0 1px -1px #484848;
    height: fit-content;
    position: fixed;
    top: 70px;
    right: 8%;
}

.tutorial-class-container {
    margin-top: 20px;
    margin-right: 20px;
    width: calc(100% - 200px);
}

.tutorial-class-item {
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    &:hover {
        background-color: $hover;
    }
}

.active-tutorial-class-item {
    font-weight: 600;
}

.tutorial-section-item {
    background-color: $hover;
    margin-bottom: 10px;
    cursor: pointer;
}

.tutorial-img {
    &-1 {
        margin-right: 100px;
    }
    &-3 {
        object-fit: contain;
        width: 90%;
        margin-bottom: 20px;
    }
}

.tutorial-page-img {
    width: 90%;
    margin: 20px 0;
    object-fit: contain;
}

@media (max-width: 1000px) {
    .tutorial-img-1 {
        margin-right: 50px;
    }
}

@media (max-width: 920px) {
    .tutorial-sections-container {
        width: 180px;
        right: 5%;
    }
}

@media (max-width: 820px) {
    .tutorial-sections-container {
        width: 160px;
        right: 4%;
    }
}

@media (max-width: 800px) {
    .tutorial-img-1 {
        margin-right: 0;
        margin-bottom: 50px;
        max-height: 250px;
        object-fit: contain;
    }
    .tutorial-img-2 {
        margin-bottom: 20px;
        max-height: 250px;
        object-fit: contain;
    }
}

@media (max-width: 600px) {
    .tutorial-sections-container {
        right: 2%;
    }
}

@media (max-width: 500px) {
    .tutorial-sections-container {
        display: none;
    }
    .tutorial-class-container {
        width: 100%;
    }
}
