@import "../../../style/vars.scss";

.moving-header- {
    item-img {
        width: 100px;
    }
    &slider {
        margin: 50px auto 10px !important;
        position: relative;
        width: 100%;
        height: 140px;
        display: grid;
        place-items: center;
        overflow: hidden;
        // border-top: 1px solid $main;
        // border-bottom: 1px solid $main;
        &::before,
        &::after {
            content: "";
            height: 100%;
            position: absolute;
            width: 10%;
            z-index: 2;
        }
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ("180deg");
        }
    }
    &slide {
        width: 265px;
        display: flex;
        align-items: center;
        padding: 10px;
        perspective: 100px;
        &-item {
            background-color: $cardBgc;
            width: 100%;
            transition: transform 1s;
            padding: 10px;
            &:hover {
                transform: translateZ(20px);
                padding: 20px;
            }
        }
    }
    &slide-track {
        animation: scroll 70s linear infinite;
        &:hover {
            animation-play-state: paused;
        }
    }
}

.moving-header-placeholder {
    height: 245px;
    height: 113px;
    background-color: red;
    &:not(:last-child) {
        margin-right: 20px;
    }
}

.homepage-trending-member-container {
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 9));
    }
}

@media (max-width: 820px) {
    .moving-header-slider {
        margin: 30px auto 20px !important;
    }
}

@media (max-width: 600px) {
    .moving-header-slider {
        margin: 30px auto 0px !important;
    }
    .moving-header-slide {
        margin-top: 0 !important;
        padding: 10px 5px 0 !important;
    }
}
