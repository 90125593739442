.return-sharpe-container {
    width: 100%;
}

.return-sharpe-item {
    width: calc(50% - 10px);
    &:not(:last-child) {
        margin-right: 20px;
    }
}

@media (max-width: 820px) {
    .return-sharpe-item {
        height: 20vh;
    }
}
