@import "../../../../style/vars.scss";

.single-index-header- {
    &container {
        margin-top: 100px;
        margin-bottom: 20px;
    }
    &title {
        margin-bottom: 5px;
        font-size: 2rem;
    }
    &sub-title {
        margin-bottom: 5px;
    }
    &item {
        border-left: 3px solid $main;
        padding-left: 5px;
        margin-left: 5px;
    }
}

.single-index-arrow-item {
    padding-bottom: 8px;
}

@media (max-width: 920px) {
    .single-index-arrow-item {
        padding-bottom: 0;
    }
    .single-index-header-title {
        margin-bottom: 10px;
    }
    .single-index-header-sub-title {
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    .single-index-header- {
        &container {
            // margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center !important;
        }
    }
    .single-index-follow-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .single-index-header-date-media {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
    }
    .single-index-follow-container {
        margin-bottom: 20px;
    }
}

@media (max-width: 820px) {
    .single-index-header-container {
        margin-top: 80px !important;
    }
}
