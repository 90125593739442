@import "../../../style/vars.scss";

.funds-container {
    // width: 1000px;
    margin: 80px auto 0;
}

.fund-title {
    &:hover {
        cursor: pointer;
        color: $secondary;
    }
}

.single-fund-item-trophies-container {
    background-color: $hover;
    padding: 10px;
    border-radius: 12px;
    width: fit-content;
    min-width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fund-crest {
    clip-path: polygon(100% 75%, 99% 0%, 0% 0%, 0% 75%, 50% 100%);
    &-img {
        object-fit: cover;
    }
    &-lvl {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $main;
        position: absolute;
        top: -10px;
        background-color: $bgc;
        z-index: 2;
        padding: 4px 6px;
        width: 50%;
        height: 25px;
        border-radius: 20%;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.2rem;
    }
}

@media (max-width: 820px) {
    .funds-container {
        margin-top: 60px !important;
    }
}

.test-level {
    width: 100px;
    margin-right: 10px;
    // border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    // border-radius: 12px;
    background-color: $bgc;
}
