@import "../../style/vars.scss";

.starter-tutorial-terms-container {
    overflow-y: auto;
    font-size: 1.2rem;
}

.tutorial-height {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 330px;
    &-extra {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 350px;
    }
}

.minimized-tutorial {
    position: fixed;
    bottom: 80px;
    right: 20px;
    border: 2px solid #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 1.5rem;
    background-color: #ffffff;
    cursor: pointer;
    z-index: 200;
}

.minimized {
    bottom: 80px;
    right: 20px;
    transform: translate(0) !important;
    transition: transform 2s ease, bottom 2s ease, right 2s ease !important;
}


.starter-tutorial-container {
    opacity: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 400px;
    width: 400px;
    background-color: white;
    border: 1px solid black;
    transition: transform 2s ease;
}

.starter-tutorial-content-header {
    padding: 20px;
    background-color: $homepageBlue;
}

.starter-tutorial-p {
    font-size: 1.2rem;
}

.starter-tutorial-content-p {
    margin-bottom: 20px;
}

.t0s0-btn {
    width: 270px;
    margin: 0 auto 10px !important;
}

.starter-tutorial-option-card-container-top {
    margin-bottom: 20px;
    // margin-right: 20;
}

.starter-tutorial-option-card {
    height: 160px;
    width: 160px;
    padding: 10px;
    border: 1px solid $main;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    &:nth-child(odd) {
        margin-right: 20px;
    }
    &:hover {
        border-color: $secondary;
        color: $secondary;
    }
}

.starter-tutorial-content-block-card {
    padding: 5px;
    border: 1px solid $main;
    border-radius: 10px;
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 20px;
    }
    &:hover {
        border-color: $secondary;
        color: $secondary;
        cursor: pointer;
    }
}

.starter-tutorial-margin-btn {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.starter-tutorial-pagination-container {
    padding: 0 20px 20px 20px;
}


.start-tutorial-progressBar-container {
margin: 10px 10px 0 10px;
border-radius: 20px;
border: 1px solid $main;
}

.starter-tutorial-icon-card-option {
    font-size: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.starter-tutorial-icon-list {
    font-size: 1.5rem;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.starter-tutorial-relative {
    position: relative;
}

.starter-tutorial-checkbox {
    position: absolute;
    bottom: 0px;
    right: 5px;
}

.starter-tutorial-close-btn {
    font-size: 1.5rem;
    cursor: pointer;
}

.starter-tutorial-continue-btn {
    padding: 6px 10px !important;
    height: 35px;
    background-color: $hover;
}