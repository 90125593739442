.fundamental-graphs-title {
    margin-bottom: 10px;
}

.chart-wrapper {
    margin-bottom: 20px;
    width: calc(50% - 10px);
    &:nth-child(2n) {
        margin-left: 20px;
        width: calc(50% - 10px);
    }
}

.port-fund-sect-chart-wrapper {
    margin-bottom: 20px;
    width: calc(50% - 10px);
    &:nth-child(2n) {
        margin-left: 20px;
        width: calc(50% - 10px);
    }
}

@media (max-width: 920px) {
    .portolio-fundamental-container {
        margin-left: 0 !important;
    }
}

@media (max-width: 820px) {
    .chart-wrapper {
        width: 100%;
        &:nth-child(2n) {
            margin-left: 0;
            width: 100%;
        }
    }
    .port-fund-sect-chart-wrapper {
        width: 100%;
        &:nth-child(2n) {
            margin-left: 0;
            width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .chart-wrapper {
        margin-bottom: 10px;
    }
    .port-fund-barcharts {
        margin-bottom: 10px !important;
    }
}
