@import "../../../../style/vars.scss";

.portfolio-stats- {
    overflow: hidden;
    transition: width 1s ease-out;
    &container {
        width: 250px;
        position: absolute;
        top: 40px;
        left: 210px;
        z-index: 5;
        padding: 10px 20px;
        background-color: #ffffff;
        color: #484848;
        box-shadow: 0 0 8px rgba(50, 98, 115, 0.4);
        border-radius: 6px;
    }
    &toggle {
        color: $negative;
        padding: 2px;
        cursor: pointer;
        position: absolute;
        left: 0;
        background-color: $main;
        border-radius: 10px;
    }
    &item {
        & > * {
            border: 0 solid #eeeeee;
            color: $main;
            background-color: inherit;
        }
        & :not(:last-child) {
            padding-bottom: 5px;
        }
    }
    &txt {
        padding: 10px 20px;
    }
}

.portfolio-stats-menu-icon-item {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.portfolio-stats-menu-data-item {
    padding: 6px;
    &:not(:last-child) {
        // margin-bottom: 10px;
    }
    &:hover {
        background-color: $hover;
    }
}

.portfolio-stats-menu-score-item {
    font-size: 1rem;
}

@media (max-width: 540px) {
    .portfolio-stats-container {
        top: 200px;
        left: 0;
    }
}
