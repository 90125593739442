@import "../../../style/vars.scss";

.single-fund-crest-lvl {
    top: 20px;
}

.single-fund-header-row {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid $hover;
}

.single-fund-action-btn {
    background-color: $hover;
    border-radius: 12px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    &:hover {
        cursor: pointer;
    }
}
