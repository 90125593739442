.technical-table-content-container {
    width: 40%;
    margin-right: 20px;
    overflow-y: auto;
}

@media (max-width: 920px) {
    .technical-table-content-container {
        overflow-y: visible !important;
    }
}


@media (max-width: 820px) {
    .technical-table-content-container {
        height: 40vh;
    }
}
