@import "../../../style/vars.scss";

.portfolio-menu- {
    &container {
        // position: fixed;
        // top:110px;
        // left: 10px;
        // background-color: $main;
        // margin: 0 auto;
        // margin-top: 10px;
        // width: 200px;
        border-radius: 8px;
        display: flex;
        align-items: center;
    }

    &options {
        position: absolute;
        top: 40px;
        border-radius: 8px;
        left: 0px;
        background-color: #ffffff;
        width: 200px;
        color: #484848;
        box-shadow: 0 0 8px rgba(50, 98, 115, 0.4);
        z-index: 1;
    }
    &item {
        padding: 10px 0;
        &:hover {
            background-color: $hover !important;
            cursor: pointer;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $main;
        }
    }
    &icon {
        margin: 0 10px;
    }
}
