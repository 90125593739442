@import "../../style/vars.scss";

.label-color-container {
    width: 150px;
}

.color-label-card-column {
    overflow-y: auto;
    margin-right: 20px;
    overflow-x: hidden;
    // min-width: 140px;
    width: 150px;
}

.color-label-card-row {
    overflow-x: auto;
    margin-bottom: 20px;
    width: 100%;
    min-height: 60px;
    flex-wrap: wrap;
}

.label-color-item {
    padding-left: 5px;
    &-span {
        &:last-child {
            padding-bottom: 20px;
        }
    }
    &-column {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &-row {
        padding-left: 5px !important;
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    &-clickable {
        font-weight: 600;
        cursor: pointer;
        &:hover {
            background-color: $hover;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

// .selected-item-color-label {
//     color: $secondary;
// }

.label-color-item-span-row {
    padding-bottom: 20px;
    margin-right: 20px;
}

@media (max-width: 920px) {
    .label-color-item {
        text-align: center;
    }
    .label-color-item-span-row {
        padding-bottom: 20px;
        margin-right: 0;
    }
}
