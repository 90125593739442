@import "../../../../style/vars.scss";

.single-currency-header- {
    &container {
        margin-top: 100px;
        margin-bottom: 20px;
    }
    &title {
        margin-bottom: 5px;
        font-size: 1.5rem;
    }
    &sub-title {
        margin-bottom: 5px;
    }
    &item {
        border-left: 3px solid $main;
        padding-left: 5px;
        margin-left: 5px;
    }
}

@media (max-width: 920px) {
    .single-currency-header- {
        &container {
            // margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center !important;
        }
    }
    .single-currency-header-title {
        display: flex;
        justify-content: center;
    }
    .single-currency-header-date {
        display: flex;
        margin: 10px auto 0;
    }
}

@media (max-width: 820px) {
    .single-currency-header-container {
        margin-top: 80px !important;
    }
    .single-currency-header-desc {
        width: 100%;
        text-align: center;
    }
}
