@import "../../style/vars.scss";

.active-page- {
    &container {
        width: 100vw;
        margin-top: 50px;
        align-items: center;
        margin: 0 auto;
        background-color: $bgc;
    }
    &item {
        // border: 1px solid red;
        // width: 150px;
        // padding: 10px;
        &:not(:last-child) {
            // margin-right: 20px;
        }
    }
    &bottom-border {
        width: 70%;
        // border-bottom: 1px solid $main;
        margin: 0 auto 50px;
    }
}

@media (max-width: 1200px) {
    .active-page-bottom-border {
        border-bottom: none;
    }
}

@media (max-width: 1000px) {
    .active-page-bottom-border {
        width: 80%;
        margin-bottom: 20px;
    }
}

@media (max-width: 820px) {
    .active-page-selection-container {
        flex-wrap: wrap;
        margin: 20px;
        & > * {
            margin-bottom: 10px;
        }
    }
    .active-page-bottom-border {
        width: 90%;
    }
}

@media (max-width: 700px) {
    .active-page-bottom-border {
        display: none;
    }
}

@media (max-width: 650px) {
    .active-page-container {
        margin: 0 auto;
        flex-wrap: wrap;
        // & > * {
        //    margin: 0 10px 10px 0 !important;
        // }
    }
}
