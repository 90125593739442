@import "../../../style/vars.scss";

.barchart-data-labels {
    text-align: center;
}

.barchart-datalabel {
    margin-bottom: 5px;
    margin-right: 20px;
}

.barchart-canvas {
    width: 70% !important;
}

.barchart-chart-container {
    position: relative;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.barchart-custom-tooltip {
    position: absolute;
    width: 200px;
}

.barchart-modal-item {
    &:first-child {
        font-weight: 600;
    }
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

@media (max-width: 1600px) {
    .barchart-chart-container {
        height: 70%;
    }
}

@media (max-width: 820px) {
    .barchart-chart-container {
        height: 60%;
    }
}