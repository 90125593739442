.single-sector- {
    &header {
        margin-bottom: 5px;
    }
    &linechart-labels-container {
        margin-top: 20px;
    }
    &competitive- {
        &table {
            margin-top: 0;
            overflow-y: auto;
        }
        &table-cell {
            width: 125px;
        }
        &table-first-cell {
            width: 90px;
        }
        &scatter-container {
            width: 100%;
            height: 100%;
        }
    }
}

.single-sector-competitive-table-cell {
    font-size: 14px;
}

.scatter-modal {
    position: absolute;
}

.single-sectors-page-filters {
    flex-wrap: wrap;
    margin-bottom: 10px;
    & > * {
        margin-bottom: 10px;
    }
}

.scatter-label- {
    &y {
        position: absolute;
        left: 0;
        top: 95%;
        transform: translate(0, -50%) rotate(-90deg);
        transform-origin: top left;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    &x {
        // position: absolute;
        // bottom: 20px;
        // width: 90%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}

@media (max-width: 920px) {
    .single-sector-competitive-table-cell {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .single-sector-competitive-table {
        margin-top: 10px !important;
    }
}

@media (max-width: 500px) {
    .single-sector-competitive-table-cell {
        font-size: 10px;
    }
}
