@import "../../style/vars.scss";

.profile-main-container {
    width: 1000px;
    margin: 0 auto;
}

.user-profile-container {
    margin-top: 80px;
}

.following-list-title {
    margin-bottom: 10px;
}

.following-list-container:not(:last-child) {
    margin-bottom: 20px;
}

.profile-about-container {
    // margin-bottom: 20px;
    min-height: 50px;
    padding-bottom: 10px;
}

.profile-pages-container {
    padding-top: 0;
    padding-bottom: 0;
}

.profile-pages-border {
    margin: 0 auto;
    border-top: 2px solid red;
    width: calc(100% - 40px);
}

.profile-about-txt {
    // max-height: 100px;
    overflow-y: auto;
    max-width: 100%;
    // margin-bottom: 10px;
    // overflow-x: auto;
    // margin-bottom: 10px;
}

.profile-header-container {
    margin-bottom: 20px;
}

.profile-portfolio-container {
}

.profile-portfolio-overall-container {
    height: 400px;
}

.profile-selected-portfolio-scores-item {
    // box-shadow: 0 0 2px rgba(50, 98, 115, 0.5);
    padding: 20px;
    border-radius: 10px;
    width: 120px;
    position: relative;
    &:hover {
        color: $secondary;
    }
    &-icon {
        margin-right: 10px;
        font-size: 2.5rem;
    }
    &-score {
        font-size: 2rem;
    }
}

.profile-selected-portfolio-totalvalue {
    font-size: 2.5rem;
}

.profile-follow-btn {
    margin-bottom: 10px !important;
    &:not(:last-child) {
        margin-right: 10px !important;
    }
}

.profile-returndays-span {
    font-size: 1.7rem;
    font-weight: 600;
}

.profile-post-return-str {
    font-size: 1.2rem;
}

.profile-post-return-icon {
    font-size: 2.5rem;
    height: 35px;
    width: 35px;
}

.profile-score-bgc {
    background-color: $hover;
    padding: 10px;
    border-radius: 30px;
    min-width: 120px;
}

.profile-post-return-str-container {
    width: 320px;
}

.profile-scores-table-row {
    padding: 20px 20px;
    font-size: 1.3rem;
    position: relative;
    &:hover {
        background-color: $hover;
        border-radius: 30px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $cardBgc;
    }
}

.profile-scores-table-icon {
    font-size: 2rem;
    margin-right: 10px;
}

.profile-about-btn {
    // width: fit-content;
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.user-profile-img {
    width: 100%;
    // font-size: 2.5rem;
    width: 150px;
    height: 150px;
    border: 1px solid $main;
    border-radius: 50%;
    object-fit: cover;
    &-container {
        position: absolute;
        bottom: 25px;
        left: 5px;
        // background-color: $bgc;
        border-radius: 20px;
        // width: 100px;
        // height: 100px;
        // border: 1px solid $main;
    }
}

.user-profile-img-no-fund {
    width: 80px;
    height: 80px;
}

/* ScoreInfoModal.css */

// .score-info-modal {
//     position: fixed;
//     background-color: #fff;
//     padding: 20px;
//     border: 1px solid #ccc;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//     z-index: 1000;
//     transition: transform 0.2s;
//     transform-origin: top left;
//     transform: scale(0);
//   }

//   .score-info-modal:hover {
//     transform: scale(1);
//   }

.profile-user-topdata {
    font-size: 1.5rem;
    height: 35px;
    min-width: 80px;

    // &:not(:last-child) {
    //     margin-right: 20px;
    // }
}

.profile-header-fundimg {
    height: 250px;
    border-radius: 12px;
    object-fit: cover;
    margin-bottom: 20px;
    width: 100%;
}

.profile-portfolio-switcher {
    width: 8px;
    height: 8px;
    background-color: $secondary;
    border-radius: 50%;
    &-opacity {
        opacity: 0;
    }
}

@media (max-width: 1120px) {
    .profile-selected-portfolio-piecharts-item {
        margin: 0 auto;
        // &:first-child {
        //     margin-bottom: 20px;
        // }
    }
}

@media (max-width: 1000px) {
    .profile-main-container {
        width: 100%;
    }
}

@media (max-width: 920px) {
    .profile-selected-portfolio-totalvalue {
        margin: 25px 0;
    }
    .profile-portfolio-overall-container {
        height: fit-content;
    }
    .profile-selected-portfolio-piecharts-item {
        width: 100%;
    }
    .profile-selected-portfolio-piecharts-pie {
        margin: 0 auto;
    }
    .user-profile-container {
        margin-top: 80px !important;
    }
}

@media (max-width: 920px) {
    .user-profile-container {
        // margin-top: 60px !important;
    }
}

@media (max-width: 820px) {
    .user-profile-container {
        margin-top: 60px !important;
    }
}

@media (max-width: 600px) {
    .user-profile-container {
        margin-top: 50px !important;
    }
    .profile-header-container {
        margin-bottom: 10px;
    }
    .profile-about-container {
        margin-bottom: 10px;
    }
    .user-pool-profile-container {
        margin-bottom: 10px;
    }
    .profile-mobile-copy-icon-btn {
        font-size: 2rem !important;
    }
}

@media (max-width: 600px) {
    .profile-score-bgc {
        padding: 10px 5px;
        min-width: 110px;
    }
    .profile-pages-container {
        flex-wrap: wrap;
    }
    .profile-pages-container {
        padding: 0 20px;
    }
    .mobile-profile-challenge-p {
        padding: 0 10px;
    }
}
