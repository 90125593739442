.table-pagination- {
    &container {
        & > *:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.pagination-page-btn {
    width: 32px;
    text-align: center;
    margin-bottom: 10px;
}

.table-pagination-btn {
    height: fit-content;
}

.fin-statements-page-btn {
    &:not(:last-child) {
        margin-right: 2px;
    }
}

@media (max-width: 820px) {
    .table-pagination-container {
        margin-bottom: 20px;
    }
    .export-excel-btn {
        width: fit-content;
        margin-left: auto;
    }
}
