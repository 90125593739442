@import "../../style/vars.scss";

.app-header- {
    &container {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100vw;
        z-index: 100;
        // padding-bottom: 20px !important;
        // border: 1px solid black;
        padding: 10px;
        // padding: 10px 10px 20px;
    }
    &logo {
        width: 50px;
        max-height: 40px;
        object-fit: contain;
    }
    &icon {
        font-size: 1.2rem;
        font-weight: 600;
        height: 40px;
        cursor: pointer;
        box-sizing: border-box;
        font-family: "Noto Sans NKo Unjoined", sans-serif;
    }
    &nav {
        height: 30px;
        & > * {
            height: 100%;
        }
    }
}

@media (max-width: 820px) {
}
