@import "../../style/vars.scss";

.page-btn {
    height: fit-content;
    text-align: center;
    color: $main;
    font-size: 1.2rem;
    background: $bgc;
    padding: 10px;
    border: 2px solid $bgc;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: $hover;
        // color: $secondary;
    }
}

.selected-page-btn {
    font-weight: 900;
    &:hover {
        cursor: default;
    }
}

@media (max-width: 900px) {
   .page-btn {
    font-size: 14px;
   }
}

@media (max-width: 680px) {
    .page-btn {
        padding: 10px;
    }
}

@media (max-width: 700px) {
    .page-btn {
        border: 2px solid transparent;
    }
}
