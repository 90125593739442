@import "../../../style/vars.scss";

.calendar-week {
    & > * {
        text-align: center;
        padding: 10px;
        flex-grow: 1;
        flex-basis: 0;
        border-right: 1px solid $main;
        border-bottom: 1px solid $main;
        &:first-child {
            border-left: 1px solid $main;
        }
    }
}
