@import "../../../../style/vars.scss";

.portfolio-switch- {
    &menu {
        box-shadow: 0 0 8px rgba(50, 98, 115, 0.4);
    }
    &container {
        width: 250px;
        position: absolute;
        top: 40px;
        left: 210px;
        background-color: $bgc;
        color: $main;
        margin: 0 auto;
        border-radius: 6px;
        z-index: 1;
    }
    &item {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        &-container {
            &:not(:last-child) {
                border-bottom: 1px solid $main;
            }
            &:hover {
                background-color: $hover !important;
            }
        }
    }
    &icon {
        margin: 0 10px;
        width: 10px;
    }
}

@media (max-width: 540px) {
    .portfolio-switch-container {
        top: 200px;
        left: 0;
    }
}
