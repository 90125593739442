@import "../../style/vars.scss";

.achievements-tutorial-content {
    font-size: 1.5rem;
    width: 500px;
}

.achievements-tutorial-content-classic {
    font-size: 1.5rem;
    max-width: 320px;
}

.achievements-tutorial-btn {
    margin-top: 10px;
    font-size: 1rem;
    height: fit-content;
}

.achievements-tutorial-close-btn {
    cursor: pointer;
    &:hover {
        background-color: $hover;
    }
}

.achievements-tutorial-counter-item {
    width: 10px;
    height: 10px;
    border: 1px solid $main;
    background-color: $hover;
    border-radius: 50%;
    &:not(:last-child) {
        margin-right: 5px;
    }
    &-current {
        background-color: $main;
    }
}

.achievements-tutorial-staricon {
    font-size: 3rem;
    color: $secondary;
    &:not(:last-child) {
        margin-right: 5px;
    }
}

@media (max-width: 650px) {
    .achievements-tutorial-card {
        max-width: 90%;
        margin: 12px auto auto auto;
        font-size: 1rem;
    }
    .achievements-tutorial-content {
        font-size: 1.2rem;
        width: 100%;
    }
}
