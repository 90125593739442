.inflation-kpi {
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;
}

.macro-inflation-container {
    width: 100%;
}

.macro-inflation-item {
    margin-bottom: 20px;
}

.macro-graph {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    // margin-bottom: 20px;
}

.macro-inflation-color-label {
    width: 200px;
    margin-right: 20px;
}

@media (max-width: 820px) {
    .macro-graph-media {
        height: 400px;
    }
}

@media (max-width: 920px) {
    .macro-inflation-color-label {
        width: 100%;
        margin-bottom: 20px;
    }
    .color-label-media {
        margin-bottom: 20px;
        width: 100%;
        min-height: 80px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .label-color-item {
        height: fit-content;
    }
    .inflation-linechart-mb20 {
        margin-bottom: 20px;
    }
}

@media (max-width: 1100px) {
    .main-inflation-container {
        flex-direction: column;
    }
    .inflation-kpi {
        flex-direction: row;
        margin-right: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
    .kpi-card-container {
        flex: 0 0 calc(50% - 10px);
        &:nth-child(odd) {
            margin-right: 20px;
        }
    }
    .macro-inflation-item {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .color-label-media {
        margin-bottom: 10px !important;
    }
}
