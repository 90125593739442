.portfolio-comparison-barcharts-container {
    margin-bottom: 20px;
}

.portfolio-comparison-barcharts-item {
    width: calc(50% - 10px);
    // height: 400px;
    &:first-child {
        margin-right: 20px;
    }
}

.portfolio-comparison-barcharts-item-chart {
}

.portfolio-comparison-barcharts-item-title {
    height: 30px;
    text-align: center;
}

.portfolio-comparison-barcharts-item-labels {
    // height: 90%;
    height: 80%;
}

@media (max-width: 1160px) {
    .portfolio-comparison-barcharts-item {
        // width: calc(50% - 10px) !important;
        // width: 100%;
        // margin-bottom: 20px;
        // height: 400px;
    }
    .app-footer {
        // margin-top: 200px;
    }
}

@media (max-width: 1015px) {
    .comparison-chart-title {
        font-size: 12px;
    }
}

@media (max-width: 920px) {
    .app-footer {
        margin-top: 0;
    }
}

@media (max-width: 820px) {
    .portfolio-comparison-barcharts-item {
        // width: 100% !important;
        &:first-child {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 780px) {
    .portfolio-comparison-barcharts-item {
        width: 100% !important;
    }
}

@media (max-width: 600px) {
    .portfolio-comparison-barcharts-item {
        width: calc(50% + 10px) !important;
        margin-bottom: 0;
        // height: 400px;
        &:first-child {
            margin-right: 10px;
        }
    }
    .comparison-chart-title {
        font-size: 10px;
    }
}

@media (max-width: 600px) {
    .portfolio-medium-screen-bottom {
        flex-direction: column;
    }
    .portfolio-comparison-barcharts-item {
        width: 100% !important;
        margin-right: 0px !important;
        &:not(:last-child) {
            margin-bottom: 10px !important;
        }
    }
}
