@import "../../style/vars.scss";

.crypto-linechart {
    flex-grow: 1;
}

.crypto-content-container {
    margin-bottom: 20px;
}

.crypto-currency {
    width: 150px;
}

.favorite-crypto {
    width: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    &-btn {
        cursor: pointer;
    }
}

.crypto-table {
    margin-bottom: 20px;
}

.crypto-table-cell {
    width: 150px;
    &-small {
        width: 80px;
    }
}
