@import "../style/vars.scss";

.user-pool- {
    &profile- {
        &container {
            margin-bottom: 20px;
        }
        &user {
            margin-bottom: 20px;
        }
    }
    &main-container {
        min-height: 100vh;
        &-mobile {
            margin-top: 120px;
        }
    }
    &container {
        width: fit-content;
        max-width: 600px;
        margin: 0 auto;
    }
    &btn {
        width: 120px;
        padding: 6px 10px;
        font-size: 1.4rem;
        margin-top: 20px;
        background-color: $bgc;
    }
    &input {
        margin: 0 auto;
        font-size: 1.2rem;
        border: none;
        outline: none;
        border: 1px solid $main;
        border-radius: 20px;
        padding: 10px;
        background-color: $hover;
        width: 70%;
        &:focus {
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &title {
        width: 100%;
        margin-bottom: 20px !important;
        font-size: 1.4rem;
    }
    &settings {
        &-container {
            margin: 20px;
        }
        &-header {
            margin-bottom: 20px;
        }
    }
}

.user-following-item {
    height: fit-content;
    &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.mobile-menu-btn {
    display: none;
    position: relative;
    font-size: 3rem;
    height: 100%;
    margin: 0 !important;
    width: fit-content;
    cursor: pointer;
    &-container {
        width: 50px;
    }
}

.mobile-menu {
    position: absolute;
    top: 60px;
    right: 10px;
    border-radius: 8px;
    width: 40vw;
    box-shadow: 0 0 8px rgba(50, 98, 115, 0.4);
    z-index: 1;

    background-color: $bgc;

    &-item {
        padding: 12px;
        font-size: 1.2rem;
        &:not(:last-child) {
            border-bottom: 1px solid $main;
        }
        &:hover {
            background-color: $hover !important;
            cursor: pointer;
        }
    }

    &-icon {
        margin-right: 10px;
        font-size: 1.5rem;
    }
}

.app-header-item {
    width: 100px;
}

.mobile-menu-btn-container {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
}

.user-auth-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.user-auth-border {
    border-right: 1px solid $main;
}

.user-pool-form {
    width: 100%;
}

.user-pool-title {
    & > h1 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.email-verification-title {
    text-align: center;
}

.email-verification-input {
    width: 100%;
}

.email-verification-btn {
    padding: 10px 20px;
    margin-top: 20px;
}

.user-auth-navbox {
    padding: 20px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 12px;
    &-selected {
        background-color: $secondary;
        color: $bgc;
        &:hover {
            background-color: $secondary !important;
        }
    }
    &-item {
        font-size: 1.5rem;
    }
    &-icon {
        font-size: 2.5rem;
    }
    &:hover {
        background-color: $hover;
    }
}

@media (max-width: 920px) {
    .user-auth-container {
        width: 450px;
    }
    .mobile-menu-btn {
        display: block;
    }
}

@media (max-width: 820px) {
    .user-auth-container {
        width: 300px;
    }
    .mobile-menu-btn-container {
        width: 30px;
    }
}

@media (max-width: 650px) {
    .mobile-menu {
        width: 50vw;
    }
}

@media (max-width: 620px) {
    .mobile-menu-btn {
        font-size: 2rem;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 600px) {
    // .user-pool-container {
    //     flex-direction: column-reverse;
    // }
    .user-pool-btn {
        margin: 0 auto;
    }
    .user-auth-container {
        width: 90% !important;
    }
    .user-pool-btn {
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .mobile-menu {
        width: 60vw;
    }

    .user-auth-container-flex {
        display: flex;
        flex-direction: column !important;
        height: calc(100vh - 120px);
        justify-content: baseline;
        width: 90%;
    }
    .user-auth-navbox {
        margin-right: 0;
        min-width: 80px;
        margin-bottom: 10px;
        padding: 10px;
    }
    .user-auth-border {
        border-right: 0;
        border-bottom: 1px solid $main;
        margin-bottom: 10px;
        flex-direction: row !important;
        padding: 0 20px;
    }
    .user-pool-form {
        margin-left: 0;
        height: 100%;
        padding: 20px 0 50px;
    }
    .user-pool-btn {
        min-width: 40%;
        // height: 35px;
    }
}
