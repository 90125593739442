@import "../../style/vars.scss";

// .portfolio-fundamental-chart-wrapper {
//     width: 100% !important;
//     margin-bottom: 0;
//     &:not(:last-child) {
//         margin-bottom: 20px;
//     }
// }

.portfolio- {
    &fundamental-chart-wrapper {
        height: 300px;
    }
    &pie {
        margin-right: 20px;
    }
    &line {
        width: 100% !important;
        height: 100%;
        flex-grow: 1;
    }
    &main-container {
    }
    &desc-item {
        margin-bottom: 20px;
    }
    &desc-title-item {
        width: 200px;
        text-align: center;
    }
    &table-title {
        text-align: center;
    }
}

.descriptive- {
    &row {
        margin-bottom: 20px;
    }
    &cell {
        // padding: 10px;
        min-width: 50px !important;
        height: 40px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    &table {
        overflow: auto;
    }
    &container {
        width: 50%;
    }
    &labels-container {
        margin: 0 !important;
        width: 10%;
    }
    &correlations-chart {
        overflow-x: auto;
        overflow-y: auto;
        margin-left: 20px;
        height: 100%;
    }
    &correlations-table-title {
        width: 100%;
    }
    &correlations-container {
        width: 45%;
        overflow-x: auto;
        overflow-y: scroll;
        font-size: 0.9rem;
        min-width: 240px;
    }
    &correlations-container::-webkit-scrollbar-track:vertical {
        display: none;
    }
    &header {
        color: $main;
        cursor: pointer;
        font-weight: 600;
        &:hover {
            background-color: $hover;
        }
    }
    &stats-title {
        font-weight: 600;
    }
    &stats-table {
        overflow: auto;
        width: 100%;
        &-symbol {
            color: $secondary;
            cursor: pointer;
            font-weight: 600;
        }
        &-header {
            & > * {
                width: 16.7%;
                text-align: center;
                margin-bottom: 10px;
                border-bottom: 1px solid $secondary;
            }
        }
    }
    &ad {
        flex: auto;
    }
}

.kde-chart {
    width: 100%;
}

.sensitivity-timefilter-container {
    margin-bottom: 20px;
}

.sensitivity-table {
    margin-bottom: 20px;
}

.portfolio-pieline {
    width: calc(100% - 170px);
    height: 100%;
}

.portfolio-piechart-item {
    width: 30%;
}

.portfolio-fundamental-chart-wrapper {
    width: calc(50% - 10px) !important;
}

.portfolio-vot-input {
    margin: 0 10px;
}

@media (max-width: 1160px) {
    .portfolio-pieline {
        width: 100%;
        margin-bottom: 20px;
    }
    .portfolio-fundamental-chart-wrapper {
        width: 100% !important;
        margin-bottom: 10px;
        margin-left: 0 !important;
    }
}

@media (max-width: 920px) {
    .portfolio-piechart-item {
        width: 100%;
    }
}

.port-desc-corr-header {
    margin-bottom: 0;
    width: 50px;
}

.port-desc-corr-container {
    padding-right: 100px;
}

.desc-correlations-media {
    padding-right: 100px;
}

.fundamental-linechart {
    height: 90% !important;
}

.portfolio-container- {
    &top {
        height: calc(60% - 20px);
        width: 100%;
    }
    &bottom {
        height: 40%;
    }
}

.portfolio-total-worth {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: 600;
}

@media (max-width: 920px) {
    .descriptive-correlations-chart {
        width: 100%;
        // margin-bottom: 20px;

        margin-right: 0;
        margin-left: 0;
    }
    .portfolio-desc-item {
        margin-bottom: 0;
    }
    .desc-table-media {
        margin-bottom: 20px;
        padding-right: 20px;
    }
    .portfolio-pie {
        margin-right: 0;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }
    .sensitivity-montecarlo-chart {
        height: 100% !important;
    }
    // .portfolio-fundamental-chart-wrapper {
    //     width: 100% !important;
    //     // &:not(:last-child) {
    //     //     margin-bottom: 0;
    //     // }
    // }
    .portfolio-top-table-media {
        margin-bottom: 20px !important;
    }
    .port-desc-corr-container {
        padding-right: 0px;
    }

    .desc-correlations-media {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0;
    }
}

.portfolio-sensitivity-top-container {
    margin-bottom: 20px;
    height: 30vh;
}

.portfolio-sensitivity-meanstd {
    margin-left: 20px;
}

// .port-desc-container-bottom {
//     height: 55%;
// }

@media (max-width: 1300px) {
    .portfolio-medium-screen- {
        &top {
            height: 60%;
            // width: 100%;
            // height: 60%;
        }
        &bottom {
            height: 40%;
            // height: calc(45%);
            // height: calc(30%) !important;
            // & > * {
            //     height: 50%;
            // }
        }
    }
    .portfolio-pieline {
        // margin-bottom: 0 !important;
    }

    .portfolio-container- {
        &bottom {
            height: 40%;
        }
    }
}

@media (max-width: 1160px) {
    .portfolio-medium-screen- {
        // &top {
        //     height: calc(50% + 80px);
        //     width: 100%;
        //     margin-bottom: 20px;
        // }
        // &bottom {
        //     height: calc(30% - 50px) !important;
        //     & > * {
        //         height: 50%;
        //     }
        // }
    }
}

.portfolio-pages-container {
    margin-top: 60px;
}

@media (max-width: 920px) {
    .portfolio-line {
        margin-bottom: 10px;
    }
    .portfolio-piechart-item {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
    .port-comp-secs-barcharts {
        margin-top: 10px !important;
    }
}

@media (max-width: 820px) {
    .portfolio-pages-container {
        margin-top: 40px !important;
    }
}

@media (max-width: 600px) {
    .portfolio-pie {
        margin-bottom: 10px;
    }
    .port-comp-secs-barcharts {
        margin-top: 10px !important;
    }
    .portfolio-top-table-media {
        margin-bottom: 10px !important;
    }
    .desc-table-media {
        margin-bottom: 10px;
    }
    .portfolio-pages-container {
        margin-top: 40px !important;
    }
    // .portfolio-fundamental-chart-wrapper {
    //     &:not(:last-child) {
    //         margin-bottom: 10px;
    //     }
    // }
}

.portfolio-compare-header-item {
    width: 130px !important;
}

.portfolio-companies-sectors-linechart {
    position: relative;
}

// .portfolio-companies-sectors-linechart-btn {
//     position: absolute;
//     top: 20px;
//     right: 20px;
// }

.portfolio-compare-input {
    // margin: 0 10px;
    width: 100%;
    // position: absolute;
    // left: 50%;
    // top: 10px;
    // transform: translateX(-50%);
}

// @media (max-width: 600px) {
//     .portfolio-companies-sectors-linechart-btn {
//         top: 10px;
//         right: 10px;
//     }
// }


