@import "../../style/vars.scss";

.calendar- {
    &page {
        width: 90%;
        margin: 0 auto;
    }
    &header {
        margin-bottom: 20px;
    }
    &main-container {
        margin-top: 20px;
    }
    &datepicker {
        padding: 5px 10px;
        width: 120px;
        border-radius: 8px;
        position: relative;
        box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
        &-item {
            font-weight: 600;
        }
        &-container {
            color: $main;
            box-shadow: 0 0 12px rgba(50, 98, 115, 0.5);
            position: absolute;
            top: 60px;
            left: 0;
            background-color: #ffffff;
            border-radius: 8px;
            padding: 20px;
        }
        &-year-container {
            margin: 0 auto;
            margin-bottom: 10px;
            width: 50%;
            font-size: 1.5rem;
        }
        &-year-btn {
            cursor: pointer;
        }
        &-filters {
            display: flex;
            flex-wrap: wrap;
        }
        &-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
        &-month {
            width: 100px;
            padding: 5px;
            box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
            background-color: #ffffff;
            border-radius: 8px;
            text-align: center;
            &:not(:last-child) {
                margin-right: 5px;
            }
            &:hover {
                cursor: pointer;
                background-color: $hover;
            }
            &-selected {
                font-weight: 600;
            }
        }
        &-btn {
            font-size: 0.8rem;
            cursor: pointer;
        }
    }
    &arrowdown {
        font-size: 1.2rem;
        cursor: pointer;
        transform: rotate(180deg);
    }
    &table-header {
        border: 1px solid $main;
        & > * {
            background-color: $bgc;
            text-align: center;
            padding: 10px;
            font-weight: 600;
            flex-grow: 1;
            flex-basis: 0;
            &:not(:last-child) {
                border-right: 1px solid $main;
            }
        }
    }
    &filters {
        margin-bottom: 30px;
    }
    &filter {
        width: 130px;
        text-align: center;
        box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
        padding: 5px 6px;
        border-radius: 8px;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &:hover {
            cursor: pointer;
            color: $hover;
        }
        &-selected {
            color: $negative;
        }
    }
}
