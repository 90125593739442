@import "../../../../style/vars.scss";

.single-commodity-header-container {
    margin-top: 100px;
}

.single-commodity-header- {
    &title {
        margin-bottom: 10px;
    }
    &item {
        border-left: 3px solid $main;
        padding-left: 5px;
        margin-left: 5px;
    }
}

.single-commodity-price-info {
    padding-bottom: 5px !important;
    margin-left: 10px !important;
}

@media (max-width: 820px) {
    
    .single-commodity-header-container {
        margin-top: 80px !important;
    }

    .single-commodity-follow-btn {
        margin-bottom: 20px;
    }
    .single-commodity-price-info {
        padding-bottom: 0 !important;
        margin-left: 0 !important;
    }
    .single-commodity-header- {
        &container {
            display: flex;
            flex-direction: column;
            align-items: center !important;
            justify-content: center;
        }
    }
    .single-commodity-item-container-media {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > * {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        & :not(:last-child) {
            margin-bottom: 5px;
        }
    }
}
