@import "../../style/vars.scss";

.about-container {
    font-size: 1.2rem;
}

.about-item {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &-title {
        margin-bottom: 5px;
    }
}

.about-us- {
    &main-container {
        min-height: 100vh;
        font-size: 1.2rem;
        padding: 20px;
        width: 60%;
        margin: 0 auto;
    }
    &about {
        margin: 20px 0;
    }
    &email {
        font-weight: 600;
    }
    &span {
        font-weight: 600;
    }
}

.about-logo- {
    &img {
        position: relative;
        margin-top: 80px;
        &-el {
            width: 200px;
            object-fit: contain;
        }
    }
    &text {
        position: absolute;
        bottom: 5px;
        font-size: 1rem;
    }
}

@media (max-width: 1300px) {
    .about-us-main-container {
        width: 70%;
    }
}

@media (max-width: 1000px) {
    .about-us-main-container {
        width: 80%;
    }
}

@media (max-width: 850px) {
    .about-us-main-container {
        width: 90%;
    }
}

@media (max-width: 820px) {
    .about-us-main-container {
        margin-top: 50px;
    }
}
