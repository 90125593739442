@import "../../../style/vars.scss";

.manage-portfolio- {
    &overlay {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }
    &container {
        margin-top: 80px;
        margin: 80px auto 20px;
        width: 80%;
        max-height: 80%;
        overflow-y: auto;
    }
}

.portfolio-table-container {
    overflow-y: auto;
    margin-bottom: 20px;
}

.profile-switch-btn {
    margin-right: 10px;
}

.portfolio-inputs-row-container {
   padding: 0 20px;
}

.portfolio-inputs-row-cell {
    width: 100%;
}

.portfolio-inputs-row-row {
    padding: 0 20px;
}

.portfolio-inputs-inputs-row {
    height: 100px;
    display: flex;
    align-items: baseline;
}

.portfolio-inputsrow-mobile-label {
    padding-left: 10px;
}

.portfolio-form-input-manage-mobile {
   width: 100% !important;
   margin-right: 0;
}


@media (max-width: 1300px) {
    .portfolio-inputs-row-cell {
        // width: 130px;
    }
}

@media (max-width: 920px) {
    .manage-portfolio-container {
        margin: 0;
        width: 90%;
        height: 90%;
    }
    .manage-portfolio-overlay { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 820px) {
    .manage-portfolio-container {
        margin: 0;
        width: 95%;
        height: 95%;
    }
}

@media (max-width: 820px) {
.manage-portfolio-container {
max-height: 90%;
}
}