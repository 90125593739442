@import "../../../../style/vars.scss";

.moving-header-item {
    &-container-homepage {
        box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
        background-color: $bgc;
        padding: 10px;
        width: 300px;
        border-radius: 8px;
    }
    &:not(:last-child) {
        border-right: 2px dashed $main;
    }
    &-img {
        width: 100px;
        & > * {
            width: 100% !important;
        }
    }
    &-info {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &price-change {
            font-size: 1.1rem;
        }
        &-changepercent {
            margin-left: 5px;
        }
    }
    &-title {
        // font-size: 1.1rem;
        font-weight: 600;
    }
    &-price {
        font-size: 1.5rem;
    }
}

.homepage-moving-header {
    width: 320px;
}

.homepage-moving-header-linechart {
    width: 40%;
}