@import "../../../style/vars.scss";

.p20 {
    padding: 0 20px 20px;
}

.portfolio-form {
    overflow: hidden;
    transition: width 0.5s ease-out;
    &.open {
        padding: 0 0 10px 0;
        width: 20vw;
        height: fit-content;
    }
    &-container {
        width: 250px;
        position: absolute;
        top: 40px;
        left: 210px;
        // padding: 10px 20px;
        padding: 20px;
        background-color: $bgc;
        box-shadow: 0 0 8px rgba(50, 98, 115, 0.4);
        margin: 0 auto;
        border-radius: 6px;
        z-index: 5;
    }
    &-item {
        padding: 10px 50px;
        &-container {
            position: relative;
            margin-bottom: 25px;
        }
    }

    &-input {
        padding: 4px 6px;
        border: 0 solid #eeeeee;
        border-bottom: 1px solid #484848;
        color: $main;
        font-size: 1rem;
        margin-bottom: 5px;
        width: 200px;
        background-color: inherit;
        outline: none !important;
        text-decoration: none !important;
        &::placeholder {
            color: $main;
        }
        &-range {
            -webkit-appearance: none;
            width: 100%;
            padding: 10px 5px;
            border-radius: 16px;
            background-color: $cardBgc;
            box-shadow: 0 0 4px rgba(50, 98, 115, 0.5);
            padding: 4px 6px;
            background-color: $hover;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $main;
                cursor: pointer;
            }
        }
    }
    &-btn {
        box-shadow: 0 0 8px rgba(50, 98, 115, 0.5);
        width: fit-content;
        padding: 4px 6px;
        background-color: $cardBgc;
        cursor: pointer;
        border-radius: 8px;
        color: $main;
    }
    &-toggle {
        // padding: 10px;
        // position: absolute;
        // left: 0;
        font-size: 2rem;
        cursor: pointer;
        padding: 0 10px;
        border-radius: 10px;
        color: $main;
        &:hover {
            color: $secondary;
        }
        // height: 100%;
    }
    &-errormsg {
        color: $negative;
        width: 200px;
    }
    &-datalist {
        background-color: $negative;
    }
    &-option {
        background-color: $negative;
    }
}

.portfolio-form-price-price {
    margin-bottom: 10px;
    text-align: center;
    color: $main;
}

.port-form-buysell {
    padding: 0 50px;
}

.wrapper {
    display: inline-flex;
    background: inherit;
    align-items: center;
    padding: 0 40px;
    border-radius: 10px;
}

.port-from-buysell-span {
    padding-left: 10px;
}

.wrapper .option {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 4px 10px;
    width: 50px;
    transition: all 0.3s ease;
}
.wrapper .option .dot {
    height: 12px;
    width: 12px;
    background: $bgc;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}
.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 1.6px;
    left: 1.6px;
    width: 10px;
    height: 10px;
    background: $main;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}
input[type="radio"] {
    display: none;
}

button[disabled] {
    cursor: default;
}

.option-1 {
    margin-right: 20px;
}

.form-range-width {
    width: 100%;
}

.portfolio-form-input-manage {
    width: 130px;
    height: 40px;
    border: 1px solid $main;
    border-radius: 18px;
    margin-right: 20px;
    background-color: $hover;
}

.manage-portfolio-checkicon {
    &:hover {
        cursor: pointer;
    }
}

.portfolio-manage-form-errormsg {
    margin-top: 5px;
    // width: 350px;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
    background: inherit;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
    background: $bgc;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span {
    font-size: 1rem;
    color: $main;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
    color: $main;
}

.css-1giiwt8-control {
    width: 200px;
}

.form-maxmin-price {
    text-align: center;
    font-size: 1rem;
    color: $main;
}

.-max {
    padding-left: 10px;
}

.-min {
    padding-right: 10px;
}

.range-input-container {
    width: 200px;
}

.width250 {
    width: 250px;
}

.portofolio-form-row {
    width: 90%;
}

.form-top-row {
    margin-bottom: 10px;
}

.portfolio-submit-container {
    width: 100%;
}

#price-input {
    text-align: center;
    border: none;
    margin-bottom: 10px;
    border-bottom: 1px solid $main;
}

@media (max-width: 1300px) {
    .portfolio-manage-form-errormsg {
//    text-align: center;
    }
    // .portfolio-form-input-manage {
    //     width: 130px;
    // }
}

@media (max-width: 540px) {
    .portfolio-form-container {
        top: 200px;
        left: 0;
    }
}
