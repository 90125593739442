$lightBlue: #5c9ead;
$darkBlue: #326273;
$orange: #e39774;
// $cardBgc: rgba(238, 238, 238, 0.6);
$cardBgc: #f5f4f9;
$homepageBlue: #c3dbe1;
$bgc: #ffffff;
$newLightBlue: #f1fdff;
$white: #ffffff;
$whiteSmoke: #f5f5f5;
$whiteFlash: #eeeeee;
$greyLight: #d5d5d5;
$greyDark: #c7c7c7;

$main: #484848;
// $secondary: #326fb5;
$secondary: #4071AB;
$alt: #56aaf0;
$positive: #84c66c;
$negative: #e74e4b;
$hover: #f1f6fc;
$bgc: #ffffff;
$cardBgc: #f5f4f9;

$yellowMustard: #FFDB58;

// extra colors
// dark blue #0e3379
// light blue #56aaf0
// yellow #f6d277