.findatasheets-logo-auth-container {
    position: relative;
    margin-right: 20px;
}

.findatasheets-logo-auth-img {
    width: 100px;
    margin-bottom: 30px;
    object-fit: contain;
}

.findatasheets-logo-auth-text {
    font-size: 1.5rem;
    position: absolute;
    bottom: 0;
}
