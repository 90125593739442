.faq-container {
    font-size: 1.4rem;
}

.faq-question {
    font-weight: 600;
}

.faq-answer {
    line-height: 165%;
}
