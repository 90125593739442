.create-fund-input {
    width: 250px;
    margin-bottom: 20px !important;
    margin-left: 0 !important;
}

.create-fund-input-dropdown {
    padding: 10px;
    width: 100px;
}

.create-fund-input-dropdown-label {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.create-fund-err-msg {
    padding-left: 10px;
    &-container {
        min-height: 20px;
    }
}
