.single-item-filters-container {
    margin: 20px auto;
}

@media (max-width: 820px) {
    .technical-content-price-container {
        flex-direction: column;
        align-items: center;
        & > * {
            width: 100%;
            margin: 0 0 20px 0 !important;
        }
        & :last-child {
            margin: 0 !important;
        }
    }
    .return-sharpe-container {
        flex-direction: column;
        align-items: center;
        & > * {
            width: 100%;
            height: calc(50% - 10px);
        }
    }
    .return-sharpe-return-container {
        margin-bottom: 20px;
        margin-right: 0 !important;
    }
}

@media (max-width: 600px) {
    .return-sharpe-return-container {
        margin-bottom: 10px;
    }
}