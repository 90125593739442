@import "../../../../../../style/vars.scss";

.homepage-create-post-container {
    width: 100%;
    margin-bottom: 10px;
}

.homepage-create-post-header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    &-item {
        margin-right: 5px;
        display: flex;
        align-items: center;
    }
}

.homepage-create-post-textarea {
    height: 80px;
    background-color: $hover;
    border-radius: 8px;
    margin-bottom: 5px;
}

.homepage-create-post-btn {
    padding: 4px 10px;
    border-radius: 12px;
}

.create-post-select-graphs-container {
    position: fixed;
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.select-graphs-item {
    padding: 5px;
    cursor: pointer;
    &:hover {
        background-color: $hover;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $hover;
    }
}

.select-graphs-checkbox {
    width: 12px;
    height: 12px;
    border: 1px solid $main;
    border-radius: 50%;
    margin-right: 10px;
}

.select-graphs-checkbox-selected {
    background-color: $main;
}

.select-graphs-graphtitle {
    font-size: 1.1rem;
    font-weight: 600;
}

.select-graphs-graphspan {
    margin-left: 10px;
    font-weight: 500;
}

.select-graphs-counter {
    padding: 10px 5px;
}

.select-graphs-btn {
    border: 2px solid $hover;
}

.selected-graphs-counter {
    margin-bottom: 10px;
}