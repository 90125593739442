@import "../../style/vars.scss";

.app- {
    &item {
        width: 250px;
        height: 250px;
        &:nth-child(2) {
            margin: 0 20px;
        }
    }
}

.homepage-left-container-copymodal-open {
    background-color: $cardBgc;
    // opacity: 0.8;
}

.homepage-main-container {
    margin-bottom: 20px !important;
}

.main-title {
    text-align: center;
    font-size: 2.5rem;
}

.item-img {
    margin-bottom: 5px;
    font-size: 3rem;
}

.item-title {
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.homepage-select {
    margin: 0 auto;
    width: 50%;
    margin: 20px 0;
}

.item-selected-content {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 20px;
    min-height: 80px;
}

.homepage-logo-img {
    position: relative;
    margin-bottom: 50px;
    &-el {
        width: 220px;
        object-fit: cover;
    }
}

.homepage-logo-text {
    position: absolute;
    bottom: -20px;
    font-size: 2.2rem;
}

@media (max-width: 1200px) {
    .homepage-select {
        width: 80%;
    }
}

@media (max-width: 820px) {
    .homepage-select {
        width: 100%;
    }
    .app-item:nth-child(2) {
        margin: 20px 0;
    }
}

.homepage-left-container {
    overflow-y: auto;
    height: calc(100vh - 60px);
    background-color: #ffffff;
    width: 340px;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1000;
    // overflow-y: auto;
    // height: calc(100vh - 215px);
    // background-color: $bgc;
    // width: 320px;

    // flex: 3;
    // flex: 1;
    // margin: 0 10px;
    // margin-left: 5vw;
}

.homepage-center-container {
    // margin: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 0 auto;
    // height: calc(100vh - 215px);
    // overflow-y: auto;
    // flex: 6;
    // flex: 2;
    // margin: 0 2vw;
}

.homepage-right-container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 330px;
    position: fixed;
    top: 70px;
    right: 10px;
    height: calc(100vh - 90px);
    // min-height: 800px;
    // // min-height: calc(100vh - 215px);

    // &::-webkit-scrollbar {
    //     width: 0.9em;
    // }

    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: darkgrey;
    //     outline: 1px solid slategrey;
    // }
}

.homepage-header-pages-more-open {
    position: absolute;
    left: 150px !important;
    top: 650px !important;
    z-index: 200;
}

.homepage-navicon-menu-item {
    padding: 20px !important;
    margin-right: 0 !important;
}

@media (max-width: 1450px) {
    .homepage-left-container {
        width: fit-content;
    }
    .homepage-navicon-menu-item {
        padding: 30px !important;
    }
    .homepage-center-container {
        max-width: none;
        width: calc(100% - 460px);
        margin-left: 100px;
        margin-right: 10px;
    }
    .hompage-main-content-container {
        justify-content: start !important;
    }
}

@media (max-width: 1170px) {
    .homepage-right-container {
        display: none;
    }
    .homepage-center-container {
        max-width: none;
        width: calc(100% - 120px);
    }
}

@media (max-width: 820px) {
    .homepage-left-container {
        display: none;
    }
    .homepage-center-container {
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .moving-header-slider {
        margin-bottom: 0px !important;
    }
    .homepage-feed-post {
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .homepage-center-container {
        width: 98%;
        margin: 0;
        margin: 0 auto;
    }
    .homepage-feed-post {
        padding: 10px !important;
    }
}
