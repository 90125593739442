@import "../../style/vars.scss";

.notepad-btn {
    // position: fixed;
    // bottom: 20px;
    // right: 20px;
    // border: 2px solid #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    // border-radius: 12px;
    font-size: 1.5rem;
    // background-color: #ffffff;
    width: 30px;
    cursor: pointer;
    z-index: 200;
    margin: 0 5px;
}

.notepad-container {
    position: fixed;
    top: 60px;
    right: 10px;
    border: 2px solid #484848;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    z-index: 200;
    width: 400px;
    height: 300px;
}

.notepad-nav {
    padding-bottom: 5px;
    border-bottom: 1px solid $main;
    margin-bottom: 10px;
}

.note-type {
    width: fit-content;
    &:not(:last-child) {
        margin-right: 5px;
        padding-right: 5px;
        border-right: 1px solid $main;
    }
}

.selected-note-type {
    font-weight: 600;
}

.notepad-notes-textarea {
    height: 240px;
}

.notepad-transaction-input {
    width: 100%;
    padding: 4px;
    margin-bottom: 5px;
    border-radius: 12px;
    border: 1px solid $main;
    margin-right: 5px;
}


.notepad-transaction-container {
    overflow-y: auto;
    height: 240px;
}

.notepad-transaction-content {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}


.notepad-transaction-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed; /* Set the table layout to fixed */
}

.notepad-transaction-table th,
.notepad-transaction-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    width: 25%; /* Set each cell to 25% width */
}

.notepad-transaction-table th {
    background-color: #f2f2f2;
}

.notepad-transaction-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.notepad-transaction-table tr:hover {
    background-color: #ddd;
}
